export const translations = {
  'en-ca': {
    'one-field-form::email::label': 'Email',
    'one-field-form::tel::label': 'Phone number',
    'one-field-form::submit::label': 'Subscribe',
    'one-field-form::submit::success': 'Thanks for signing up!',
    'one-field-form::submit::failure': 'Oops! Something went wrong.',
  },
  'fr-ca': {
    'one-field-form::email::label': 'Courriel',
    'one-field-form::tel::label': 'Numéro de téléphone',
    'one-field-form::submit::label': 'Abbonez-vous',
    'one-field-form::submit::success': 'Merci! À  bientôt!',
    'one-field-form::submit::failure': 'Oups! Ça n’a pas fonctionné.',
  },
};
