export const translations = {
  'en-ca': {
    'tldr-form::input_label': 'Enter your email',
    'tldr-form::consent':
      'By providing your email, you are consenting to receive communications from Wealthsimple Media Inc. Visit our [Privacy Policy](https://www.wealthsimple.com/en-ca/legal/privacy) for more info, or contact us at [privacy@wealthsimple.com](mailto:privacy@wealthsimple.com) or 80 Spadina Ave., Toronto, ON.',
  },
  'fr-ca': {
    'tldr-form::input_label': 'Courriel',
    'tldr-form::consent':
      'En entrant votre courriel, vous consentez à recevoir des communications de la part de Wealthsimple Media Inc. Consultez notre [Politique de protection des renseignements personnels](https://www.wealthsimple.com/fr-ca/legal/privacy) ou écrivez-nous à [privacy@wealthsimple.com](mailto:privacy@wealthsimple.com) ou à 80, av. Spadina, Toronto, ON.',
  },
};
