/**
 * Converts a string to an object with a `data-nc-xpath` key for use in XPath selectors in Netlify Create annotations
 *
 * @param {string} string - The string to use as the `data-nc-xpath` value.
 * @returns {Object} - An object with a `data-nc-xpath` key with the value of the string param in development, or an empty object in production.
 */
export const toXPathData = string => {
  return process.env.NODE_ENV === 'production' ? {} : {
    'data-nc-xpath': string,
  };
};

/**
 * Generates an XPath selector string to be used in `toFieldPath` annotations for Netlify Create (as the xpath property).
 * @param {string} selectorDataAttributeName - The data-nc-path attribute value of the element we want to target
 * @param {Object} [options] - Additional options
 * @param {string} [options.useText] - Whether to use the xpath `text()` function to target the text content of the element
 * @returns {string} - A valid xpath selector for the @stackbit/annotations' `toFieldPath`'s xpath property.
 * @example
 * import { toFieldPath } from '@stackbit/annotations';
 *
 * // Child element we want to be inline-editable:
 * <div {...toXPathData('heading')}>{heading}</div>
 * // Becomes:
 * <div data-nc-xpath="heading">{heading}</div>
 *
 * // Parent element we have the ability to annotate, where `headingField` is the Contentful field name:
 * <div {...toFieldPath({fieldName: 'headingField', xpath: generateXPathSelectorString('heading')})} />
 * // Becomes:
 * <div data-sb-field-path="headingField#.//*[@data-nc-xpath = \"heading\"]" />
 */
export const generateXPathSelectorString = (selectorDataAttributeName, options = {}) => {
  const { useText } = options;
  return `.//*[@data-nc-xpath="${selectorDataAttributeName}"]${useText ? '//text()' : ''}`;
};
