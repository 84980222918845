/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const QRIcon = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<rect x={1} y={1} width={28} height={27} rx={3} fill="#FCFCFC" stroke="#FCFCFC" strokeWidth={2} /><rect x={4.94141} y={5} width={7.82353} height={7.82353} fill={color} /><rect x={4.94141} y={16.1765} width={7.82353} height={7.82353} fill={color} /><rect x={7.17578} y={18.4117} width={3.35294} height={3.35294} fill="#FCFCFC" /><rect x={7.17578} y={7.23535} width={3.35294} height={3.35294} fill="#FCFCFC" /><rect x={17.2344} y={5} width={7.82353} height={7.82353} fill={color} /><rect x={19.4727} y={7.23535} width={3.35294} height={3.35294} fill="#FCFCFC" /><rect x={17} y={16} width={3} height={3} fill={color} /><rect x={20} y={19} width={5} height={5} fill={color} /></svg>;
};
QRIcon.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default QRIcon;