'use client';

import React, { useEffect, useLayoutEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { cookieSet, cookieGet } from 'src/utils/cookies';
import { classNames } from 'src/utils/css';
import { parseJsonSafely } from 'src/utils/json';
import { Markdown } from 'src/tapestry/core/markdown';
import { trackAnalyticsEvent } from 'src/services/analytics-service';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { ComponentContextProvider } from 'src/components/layout';
import { colorText } from 'src/styles/exports.module.scss';
import { CTAButton } from '../CTAButton';
import { FluidText } from '../FluidText';
import { Close } from '../Icons';
import { SegmentIoScript, loadSegmentAnalyticsJs } from '../SegmentIo';
import { cookieBannerReducer } from './CookieBanner.reducer';
import {
  wrapper_,
  content_,
  buttonsContainer_,
  disclosureText_,
  closeButton_,
  chatbotSpacing_,
  themeMagazine_,
} from './CookieBanner.module.scss';
import { translations } from './CookieBanner.translations.js';

const EVENTS = {
  DISPLAYED: 'Cookie banner displayed',
  DISMISSED: 'Cookie banner dismissed',
  ACCEPTED_ALL: 'Cookie banner accepted all',
  ACCEPTED_NECESSARY: 'Cookie banner accepted necessary',
};
const COOKIE_NAME = 'tracking-consent';
const COOKIE_ATTRIBUTES = {
  sameSite: 'strict',
  secure: true,
  expires: 365,
};

const setCookieBannerCookie = body => {
  cookieSet(COOKIE_NAME, JSON.stringify(body), false, COOKIE_ATTRIBUTES);
};

const getCookieBannerCookie = () => parseJsonSafely(cookieGet(COOKIE_NAME));

const THEME_MAP = {
  magazine: themeMagazine_,
};

export const CookieBanner = ({ withChatbot = false, shouldForceDisplay = false, theme }) => {
  const { isCookieBannerEligible } = useUserAttributesContext();
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const textDisclosure = useTranslation('cookie-banner::disclosure-text');
  const textDeny = useTranslation('cookie-banner::cta::deny');
  const textAccept = useTranslation('cookie-banner::cta::allow');
  const textClose = useTranslation('cookie-banner::close-label');

  const INITIAL_STATE = {
    isEligible: isCookieBannerEligible,
    hasPreference: false,
    doesConsent: null,
    shouldShowBanner: false,
    shouldLoadSegment: false,
  };
  const [state, updateState] = useReducer(cookieBannerReducer, INITIAL_STATE);

  const saveConsent = consentValue => {
    setCookieBannerCookie({ doesConsent: consentValue });
    updateState({
      hasPreference: true,
      doesConsent: consentValue,
    });
  };

  const handleAcceptAll = () => {
    trackAnalyticsEvent(EVENTS.ACCEPTED_ALL);
    saveConsent(true);
  };

  const handleOnlyNecessary = () => {
    trackAnalyticsEvent(EVENTS.ACCEPTED_NECESSARY);
    saveConsent(false);
  };

  const handleDismiss = () => {
    trackAnalyticsEvent(EVENTS.DISMISSED);
    saveConsent(false);
  };

  // force a state recalculation once the API returns a usable value
  useEffect(() => {
    updateState({ isEligible: isCookieBannerEligible });
  }, [isCookieBannerEligible]);

  // check for saved consent value
  useLayoutEffect(() => {
    const cookieValue = getCookieBannerCookie();

    if (
      !cookieValue ||
      [null, undefined].includes(cookieValue?.doesConsent) ||
      ![true, false].includes(cookieValue.doesConsent)
    ) {
      updateState({ hasPreference: false });
    } else {
      updateState({ hasPreference: true, doesConsent: cookieValue.doesConsent });
    }
  }, [state.isEligible]);

  // only for track events on banner displayed
  useEffect(() => {
    if (state.shouldShowBanner === true) {
      trackAnalyticsEvent(EVENTS.DISPLAYED);
    }
  }, [state.shouldShowBanner]);

  // actually load segment once we have a consent value
  useLayoutEffect(() => {
    if (state.shouldLoadSegment === true) {
      loadSegmentAnalyticsJs({ consent: state.doesConsent ?? true });
    }
  }, [state]);

  return (
    <>
      <SegmentIoScript />
      {(state.shouldShowBanner || shouldForceDisplay) && (
        <ComponentContextProvider value={{ name: 'CookieBanner' }}>
          <div className={classNames(wrapper_, THEME_MAP[theme])} data-testid="CookieBanner">
            <div className={classNames(content_, withChatbot && chatbotSpacing_)}>
              <FluidText type="div" all="ws-text-md" className={disclosureText_}>
                <Markdown source={textDisclosure} />
              </FluidText>
              <div className={buttonsContainer_}>
                <CTAButton size="sm" variant="secondary" onClick={handleOnlyNecessary}>
                  {textDeny}
                </CTAButton>
                <CTAButton size="sm" onClick={handleAcceptAll}>
                  {textAccept}
                </CTAButton>
                <button type="button" className={closeButton_} onClick={handleDismiss}>
                  <span className="sr-only">{textClose}</span>
                  <Close color={colorText} />
                </button>
              </div>
            </div>
          </div>
        </ComponentContextProvider>
      )}
    </>
  );
};

CookieBanner.propTypes = {
  withChatbot: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shouldForceDisplay: PropTypes.bool,
  theme: PropTypes.string,
};
