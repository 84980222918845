import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { REFRESH_BREAKPOINTS } from 'src/utils/css';
import { AnimatedScrollBlock } from 'src/tapestry/animation/animated-scroll-block';
import { borderRadiusDefault } from 'src/styles/exports.module.scss';

const ANIMATION_DEFAULT = 'squeeze';
const ANIMATION_IN_OUT = 'squeeze-in-out';

const ANIMATION_TRIGGERS = {
  [ANIMATION_DEFAULT]: {
    startPosition: `top-=54 top`,
    endPosition: `bottom-=25% top`,
    startPositionMobile: `bottom bottom-=30%`,
    endPositionMobile: `bottom top`,
  },
  [ANIMATION_IN_OUT]: {
    startPosition: `top bottom`,
    endPosition: `bottom top`,
    startPositionMobile: `top bottom`,
    endPositionMobile: `bottom top`,
  },
};

const squeezeTimeline = (config, dependencies, spacing, radius, triggerPositions) => {
  const { ref, animationName } = dependencies;

  // override default squeeze positions for mobile, since they will be unique for mobile and desktop
  if (triggerPositions) config.scrollTrigger = { ...config.scrollTrigger, ...triggerPositions };

  const timeline = gsap.timeline(config);
  timeline.to({ dummy: 0 }, { duration: 1 }, 0);

  switch (animationName) {
    case ANIMATION_DEFAULT:
      timeline.fromTo(
        ref.current,
        { clipPath: `inset(0px 0px round 0px)` },
        {
          clipPath: `inset(0px ${spacing} round ${radius})`,
          duration: 1,
          ease: 'power2.inOut',
        },
        0
      );
      break;
    case ANIMATION_IN_OUT:
      timeline.set(ref.current, { clipPath: `inset(0px ${spacing} round ${radius})` }, 0);
      timeline.to(
        ref.current,
        {
          clipPath: `inset(0px 0px round 0px)`,
          duration: 0.35,
          ease: 'power2.out',
        },
        0.1
      );
      timeline.to(
        ref.current,
        {
          clipPath: `inset(0px ${spacing} round ${radius})`,
          duration: 0.35,
          ease: 'power2.out',
        },
        0.65
      );
      break;
    default:
      throw new Error('Improper animation name passed.');
  }

  return () => {
    if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
    if (timeline) timeline.kill();
  };
};

const breakpointMap = (breakpoint, animationName, config, dependencies) =>
  ({
    sm: {
      [`(min-width: ${REFRESH_BREAKPOINTS.xs}px) and (max-width: ${REFRESH_BREAKPOINTS.md -
        1}px)`]: () => {
        if (animationName === ANIMATION_DEFAULT) {
          squeezeTimeline(config, dependencies, '24px', borderRadiusDefault, {
            start: ANIMATION_TRIGGERS[animationName].startPositionMobile,
            end: ANIMATION_TRIGGERS[animationName].endPositionMobile,
          });
        }
      },
    },
    md: {
      [`(min-width: ${REFRESH_BREAKPOINTS.md}px) and (max-width: ${REFRESH_BREAKPOINTS.xl -
        1}px)`]: () => squeezeTimeline(config, dependencies, '32px', borderRadiusDefault),
    },
    lg: {
      [`(min-width: ${REFRESH_BREAKPOINTS.xl}px)`]: () =>
        squeezeTimeline(config, dependencies, '40px', borderRadiusDefault),
    },
  }[breakpoint]);
const BREAKPOINT_LIST = ['sm', 'md', 'lg'];

const timeline = (config, dependencies) => {
  const { animationName = ANIMATION_DEFAULT, breakpoints } = dependencies;

  return breakpoints.reduce(
    (obj, breakpoint) => ({
      ...obj,
      ...breakpointMap(breakpoint, animationName, config, dependencies),
    }),
    {}
  );
};

const SectionSqueezeAnimation = forwardRef(function SectionSqueezeAnimation(
  { children, trigger, id, animationName = ANIMATION_DEFAULT, breakpoints = BREAKPOINT_LIST },
  ref
) {
  return (
    <AnimatedScrollBlock
      id={id}
      timeline={timeline}
      startPosition={ANIMATION_TRIGGERS[animationName].startPosition}
      endPosition={ANIMATION_TRIGGERS[animationName].endPosition}
      trigger={trigger}
      scrub={0.75}
      timelineDependencies={{ ref, animationName, breakpoints }}
    >
      {children}
    </AnimatedScrollBlock>
  );
});

SectionSqueezeAnimation.propTypes = {
  animationName: PropTypes.oneOf([ANIMATION_DEFAULT, ANIMATION_IN_OUT]),
  children: PropTypes.node,
  trigger: PropTypes.node,
  id: PropTypes.string,
  breakpoints: PropTypes.arrayOf(PropTypes.oneOf(BREAKPOINT_LIST)),
};

export { SectionSqueezeAnimation };
