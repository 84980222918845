const logWarning = err => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(`Warning: ${err}`);
  }
};

const logError = err => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(`Error: ${err}`);
  }
};

export const Logger = {
  warn: logWarning,
  error: logError,
};
