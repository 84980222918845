import React from 'react';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { container, button } from './SkipToMainContent.module.scss';

const SkipToMainContent = () => {
  return (
    <div className={container} data-testid="skip-to-main-content">
      <CTALinkButton className={button} href="#main" variant="secondary" shouldFocus>
        {useTranslation('header::cta::skip_to_main_content')}
      </CTALinkButton>
    </div>
  );
};

export { SkipToMainContent };
