'use client';

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import {
  ACCOUNTS_PATH,
  ACCOUNTS_RRSP_PATH,
  ACCOUNTS_TFSA_PATH,
  ACCOUNTS_FHSA_PATH,
  PRODUCT_TRADE_PATH,
  PRODUCT_CRYPTO_PATH,
  PRODUCT_OPTIONS_PATH,
  PRODUCT_SPEND_PATH,
  PRODUCT_MORTGAGES_PATH,
  PRODUCT_TAX_PATH,
  PRICING_PATH,
  HELP_SITE_URL_EN,
  HELP_SITE_URL_FR,
  LEARN_PATH,
  MAGAZINE_PATH,
  FEE_CALCULATOR_PATH,
  RETIREMENT_CALCULATOR_PATH,
  RRSP_CALCULATOR_PATH,
  TAX_CALCULATOR_PATH_EN,
  TAX_CALCULATOR_PATH_FR,
  TFSA_CALCULATOR_PATH,
  TRANSFERS_PATH,
  LOCALIZED_LOGIN_URL,
  PRODUCT_INVEST_PATH,
  INVEST_PORTFOLIO_CLASSIC,
  INVEST_PORTFOLIO_SRI,
  INVEST_PORTFOLIO_HALAL,
  PRODUCT_PRIVATECREDIT_PATH,
  PRODUCT_PRIVATEEQUITY_PATH,
  CONTACT_PATH,
} from 'src/utils/urls';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { AppDownloadCTA } from 'src/components/common/AppDownloadCTA';
import { COMPONENT_NAMES } from 'src/services/analytics-service';
import { Logger } from 'src/utils/logging';
import { Navigation } from './Navigation/Navigation.component';
import {
  sticky_,
  relative_,
  navScrollDetector_,
  background_,
  transparentScrollTop_,
  reducedAnimation_,
} from './Header.module.scss';
import { translations } from './Header.translations';
import { SkipToMainContent } from './SkipToMainContent';
import { ComponentContextProvider } from '../ComponentContext';

const filterConditionalLinks = links =>
  links.filter(link => (link?.condition ? link.condition() : true));

const filterNestedConditionalLinks = links => {
  // copy, and filter top level
  const filteredLinks = filterConditionalLinks(links);

  // nested
  filteredLinks.forEach(link => {
    if (link?.categories?.columns) {
      link.categories.columns = link.categories.columns.map(column => {
        return {
          ...column,
          links: filterConditionalLinks(column.links),
        };
      });
    }
  });

  return filteredLinks;
};

export const Header = ({
  links,
  primaryCta,
  secondaryCta,
  renderLogo,
  notificationBanner,
  isTransparent = false,
  isSticky = true,
  ...rest
}) => {
  const { appendTranslationKeys, isEn, isFr } = useTranslationContext();
  appendTranslationKeys(translations);

  const { prefersReducedMotion } = useMotionOptOutContext();

  const [_isTransparent, setIsTransparent] = useState(isTransparent);

  const scrollDetectorRef = useRef();

  useEffect(() => {
    if (isTransparent) {
      const scrollObserver = new IntersectionObserver(entries => {
        if (entries?.[0].boundingClientRect.y < 0) {
          setIsTransparent(false);
        } else {
          setIsTransparent(true);
        }
      });

      if (scrollDetectorRef.current) {
        scrollObserver.observe(scrollDetectorRef.current);
      }
      return () => {
        scrollObserver.disconnect();
      };
    }
    return undefined;
  }, [scrollDetectorRef, isTransparent]);

  const defaultPrimaryCta = <AppDownloadCTA />;
  const defaultSecondaryCta = {
    href: LOCALIZED_LOGIN_URL,
    label: useTranslation('header::cta::login'),
  };

  const defaultLinks = [
    {
      label: useTranslation('header::nav::managed_investing'),
      categories: {
        introduction: {
          heading: useTranslation('header::nav::managed_investing::info::heading'),
          subheading: useTranslation('header::nav::managed_investing::info::subheading'),
          href: PRODUCT_INVEST_PATH,
        },
        columns: [
          {
            heading: useTranslation(`header::nav::managed_investing::category::portfolios`),
            links: [
              {
                label: useTranslation(
                  `header::nav::managed_investing::category::portfolios::classic`
                ),
                href: INVEST_PORTFOLIO_CLASSIC,
              },
              {
                label: useTranslation(
                  'header::nav::managed_investing::category::portfolios::socially_responsible'
                ),
                href: INVEST_PORTFOLIO_SRI,
              },
              {
                label: useTranslation(
                  'header::nav::managed_investing::category::portfolios::halal'
                ),
                href: INVEST_PORTFOLIO_HALAL,
              },
            ],
          },
          {
            heading: useTranslation(
              `header::nav::managed_investing::category::alternatives_investing`
            ),
            links: [
              {
                label: useTranslation(
                  `header::nav::managed_investing::category::alternatives_investing::private_credit`
                ),
                href: PRODUCT_PRIVATECREDIT_PATH,
              },
              {
                label: useTranslation(
                  `header::nav::managed_investing::category::alternatives_investing::private_equity`
                ),
                href: PRODUCT_PRIVATEEQUITY_PATH,
              },
            ],
          },
        ],
      },
    },
    {
      label: useTranslation('header::nav::self_directed_investing'),
      categories: {
        introduction: {
          heading: useTranslation('header::nav::self_directed_investing::info::heading'),
          subheading: useTranslation('header::nav::self_directed_investing::info::subheading'),
          href: PRODUCT_TRADE_PATH,
        },
        columns: [
          {
            heading: useTranslation(
              `header::nav::self_directed_investing::category::investing_products`
            ),
            links: [
              {
                label: useTranslation(
                  `header::nav::self_directed_investing::category::investing_products::stocks_and_etfs`
                ),
                href: PRODUCT_TRADE_PATH,
              },
              {
                label: useTranslation(
                  `header::nav::self_directed_investing::category::investing_products::options_trading`
                ),
                href: PRODUCT_OPTIONS_PATH,
              },
            ],
          },
        ],
      },
    },
    {
      label: useTranslation('header::nav::spend'),
      href: PRODUCT_SPEND_PATH,
    },
    {
      label: useTranslation(`header::nav::crypto`),
      href: PRODUCT_CRYPTO_PATH,
    },
    {
      label: useTranslation(`header::nav::mortgages`),
      href: PRODUCT_MORTGAGES_PATH,
      condition: () => isEn,
    },
    {
      label: useTranslation(`header::nav::tax`),
      href: PRODUCT_TAX_PATH,
    },
    {
      label: useTranslation('header::nav::account_types'),
      categories: {
        introduction: {
          heading: useTranslation('header::nav::account_types::info::heading'),
          subheading: useTranslation('header::nav::account_types::info::subheading'),
          href: ACCOUNTS_PATH,
        },
        columns: [
          {
            heading: useTranslation(`header::nav::account_types::category::investing`),
            links: [
              {
                label: useTranslation(`header::nav::account_types::category::investing::rrsp`),
                href: ACCOUNTS_RRSP_PATH,
              },
              {
                label: useTranslation(`header::nav::account_types::category::investing::tfsa`),
                href: ACCOUNTS_TFSA_PATH,
              },
              {
                label: useTranslation(`header::nav::account_types::category::investing::fhsa`),
                href: ACCOUNTS_FHSA_PATH,
              },
              {
                label: useTranslation(`header::nav::account_types::category::investing::all`),
                href: ACCOUNTS_PATH,
              },
            ],
          },
          {
            heading: useTranslation(`header::nav::account_types::category::spending_and_saving`),
            links: [
              {
                label: useTranslation(
                  `header::nav::account_types::category::spending_and_saving::high_interest_cash_account`
                ),
                href: PRODUCT_SPEND_PATH,
              },
            ],
          },
        ],
      },
    },
  ];

  const defaultLinksSecondary = [
    {
      label: useTranslation('header::nav::pricing'),
      href: PRICING_PATH,
    },
    {
      label: useTranslation('header::nav::support'),
      categories: {
        introduction: {
          heading: useTranslation('header::nav::support::info::heading'),
          subheading: useTranslation('header::nav::support::info::subheading'),
        },
        columns: [
          {
            heading: useTranslation(`header::nav::support::category::help`),
            links: [
              // {
              //   label: useTranslation(
              //     `header::nav::support::category::help::portfolio_review_service`
              //   ),
              //   href: TRANSFERS_PATH,
              // },
              {
                label: useTranslation(`header::nav::support::category::help::transfer_account`),
                href: TRANSFERS_PATH,
              },
              {
                label: useTranslation(`header::nav::support::category::help::help_centre`),
                href: isFr ? HELP_SITE_URL_FR : HELP_SITE_URL_EN,
              },
              {
                label: useTranslation(`header::nav::support::category::help::contact`),
                href: CONTACT_PATH,
              },
            ],
          },
          {
            heading: useTranslation(`header::nav::support::category::learn`),
            links: [
              {
                label: useTranslation(
                  `header::nav::support::category::learn::personal_finance_tips`
                ),
                href: LEARN_PATH,
              },
              {
                label: useTranslation(
                  `header::nav::support::category::learn::wealthsimple_magazine`
                ),
                href: MAGAZINE_PATH,
              },
              // Hold for Q2 2024
              // {
              //   label: useTranslation(`header::nav::support::category::learn::product_updates`),
              //   href: HELP_SITE_URL,
              // },
            ],
          },
          {
            heading: useTranslation(`header::nav::support::category::tools`),
            links: [
              {
                label: useTranslation(`header::nav::support::category::tools::tax_calculator`),
                href: isFr ? TAX_CALCULATOR_PATH_FR : TAX_CALCULATOR_PATH_EN,
              },
              {
                label: useTranslation(`header::nav::support::category::tools::rrsp_calculator`),
                href: RRSP_CALCULATOR_PATH,
                condition: () => isEn,
              },
              {
                label: useTranslation(`header::nav::support::category::tools::tfsa_calculator`),
                href: TFSA_CALCULATOR_PATH,
                condition: () => isEn,
              },
              {
                label: useTranslation(
                  `header::nav::support::category::tools::retirement_calculator`
                ),
                href: RETIREMENT_CALCULATOR_PATH,
                condition: () => isEn,
              },
              {
                label: useTranslation(`header::nav::support::category::tools::fee_calculator`),
                href: FEE_CALCULATOR_PATH,
                condition: () => isEn,
              },
            ],
          },
        ],
      },
    },
  ];

  const primaryCtaProp = primaryCta ?? defaultPrimaryCta;
  const secondaryCtaProp = secondaryCta ?? defaultSecondaryCta;
  const linksProp = filterNestedConditionalLinks(links ?? defaultLinks);
  const secondaryLinksProp =
    linksProp.length > 0 ? filterNestedConditionalLinks(defaultLinksSecondary) : [];

  const hasLinks = !!linksProp?.length;

  if (hasLinks && _isTransparent) {
    Logger.warn(
      'Cannot set `<Header>` background to transparent when "links" are present. Either set "links" to an empty array or remove "isTransparent" property'
    );
  }

  return (
    <ComponentContextProvider value={{ name: COMPONENT_NAMES.DESKTOP_NAV }}>
      <div className={navScrollDetector_} ref={scrollDetectorRef} />
      <div
        className={classNames(
          isSticky ? sticky_ : relative_,
          prefersReducedMotion && reducedAnimation_
        )}
        data-cy={isSticky ? 'sticky-header' : 'relative-header'}
        {...rest}
      >
        <SkipToMainContent />
        {notificationBanner}
        <div
          className={classNames(background_, !hasLinks && _isTransparent && transparentScrollTop_)}
          id="headerWrapper"
        >
          <header>
            <Navigation
              links={linksProp}
              linksSecondary={secondaryLinksProp}
              primaryCta={primaryCtaProp}
              secondaryCta={secondaryCtaProp}
              renderLogo={renderLogo}
            />
          </header>
        </div>
      </div>
    </ComponentContextProvider>
  );
};

Header.propTypes = {
  /** An array of link objects for the nav content */
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  })),
  /** An object, or an instance of CTALinkButton, for the rightmost (primary) CTA in the nav */
  primaryCta: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({}),
    PropTypes.shape({
      label: PropTypes.node,
      href: PropTypes.string,
    }),
  ]),
  /** An object (NOT a node) for the left (secondary) CTA in the nav */
  secondaryCta: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.shape({
      label: PropTypes.node,
      href: PropTypes.string,
    }),
  ]),
  /** A function which renders an alternate logo for the main nav. */
  renderLogo: PropTypes.func,
  /** Whether or not the nav should appear transparent to start. Becomes white on scroll. Not able to be used if links are present. */
  isTransparent: PropTypes.bool,
  /** A React element to display above the main nav. Usually of type `<NotificationBanner>` */
  notificationBanner: PropTypes.node,
  /** Whether or not the nav should stick to the top of the window on scroll. */
  isSticky: PropTypes.bool,
};
