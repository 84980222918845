export const translations = {
  'en-ca': {
    'app_download_cta::label::download_app': 'Download the app',
    'app_download_cta::label::signup': 'Get started',
  },
  'fr-ca': {
    'app_download_cta::label::download_app': 'Télécharger l’appli',
    'app_download_cta::label::signup': 'Inscription',
  },
};
