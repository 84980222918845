'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { Markdown } from 'src/tapestry/core/markdown';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { Instagram, XLogo, YouTube, LinkedIn } from 'src/components/common/Icons';
import { LanguageSelect } from 'src/components/common/LanguageSelect';
import { TLDRFooter } from 'src/components/common/TLDRFooter';
import { COMPONENT_NAMES } from 'src/services/analytics-service';

import ciro_en from './media/ciro_en.png';
import ciro_fr from './media/ciro_fr.png';
import cipf_en from './media/cipf_en.png';
import cipf_fr from './media/cipf_fr.png';

import {
  background,
  footerGrid,
  footerLinks,
  footerCell,
  languagePicker,
  footerContainer,
  legalDisclaimer,
  regulatoryLinks,
  linkIcon as linkIconClassname,
} from './Footer.module.scss';
import { ComponentContextProvider } from '../ComponentContext';

const FooterLink = ({ label, url, Icon, ...rest }) => (
  <li>
    <SmartLink href={url} {...rest}>
      {Icon && <Icon size="md" id={label} className={linkIconClassname} />}
      {label}
    </SmartLink>
  </li>
);

const FooterColumn = ({ columnTranslationId, links, linkTranslationIds, linkIcons }) => {
  const rootTranslationId = `footer::${columnTranslationId}`;
  const headingLabel = useTranslation(`${rootTranslationId}::label`);

  if (columnTranslationId) {
    return (
      <div className={footerCell}>
        <h3 className="ws-display-3xs-sans">{headingLabel}</h3>
        <ul className={`${footerLinks} ws-text-lg`}>
          {links
            ? links
              .filter(link => !link?.shouldExclude)
              .map(({ label, url }) => (
                <FooterLink
                  key={label}
                  label={label}
                  url={url}
                  eventData={{ colname: headingLabel }}
                />
              ))
            : linkTranslationIds
              .filter(linkId => !linkId?.shouldExclude)
              .map(linkId => {
                const _linkId = linkId.linkTranslationId ?? linkId;
                /* eslint-disable react-hooks/rules-of-hooks */
                const label = useTranslation(`${rootTranslationId}::${_linkId}::label`);
                const url = useTranslation(`${rootTranslationId}::${_linkId}::url`);
                /* eslint-enable react-hooks/rules-of-hooks */
                const Icon = linkIcons && linkIcons[_linkId];

                return (
                  <FooterLink
                    key={_linkId}
                    label={label}
                    url={url}
                    Icon={Icon}
                    eventData={{ colname: headingLabel }}
                  />
                );
              })}
        </ul>
      </div>
    );
  }

  return null;
};

export const Footer = ({ showRegulatoryLinks = false, disclaimerText, showTLDRFooter = true, ...rest }) => {
  const { isFr } = useTranslationContext();

  const ciro = {
    alt: useTranslation('footer::disclaimers::ciro::alt'),
    url: useTranslation('footer::disclaimers::ciro::url'),
  };
  const cipf = {
    alt: useTranslation('footer::disclaimers::cipf::alt'),
    url: useTranslation('footer::disclaimers::cipf::url'),
  };

  const defaultDisclaimerText = useTranslation('footer::disclaimers::text');
  const finalDisclaimerText = disclaimerText ?? defaultDisclaimerText;

  const footerColumnData = [
    {
      columnTranslationId: 'about_us',
      linkTranslationIds: ['company', { linkTranslationId: 'news', shouldExclude: isFr }, 'careers', 'foundation', 'giveback'],
    },
    {
      columnTranslationId: 'legal',
      linkTranslationIds: ['accessibility', 'privacy', 'terms'],
    },
    {
      columnTranslationId: 'accounts',
      linkTranslationIds: [
        'rrsp',
        'tfsa',
        'fhsa',
        'non-registered',
        { linkTranslationId: 'spousal-rrsp', shouldExclude: isFr },
        { linkTranslationId: 'resp', shouldExclude: isFr },
        { linkTranslationId: 'corporate-managed', shouldExclude: isFr },
        'lira', 'all'],
    },
    {
      columnTranslationId: 'products',
      linkTranslationIds: [
        'managed',
        'self-directed',
        'private-credit',
        'private-equity',
        'cash',
        'crypto',
        'tax',
        { linkTranslationId: 'mortgages', shouldExclude: isFr },
        { linkTranslationId: 'work', shouldExclude: isFr },
      ],
    },
    {
      columnTranslationId: 'social',
      linkTranslationIds: ['ig', 'x', 'yt', 'li'],
      linkIcons: {
        ig: Instagram,
        x: XLogo,
        yt: YouTube,
        li: LinkedIn,
      },
    },
  ];

  return (
    <ComponentContextProvider value={{ name: COMPONENT_NAMES.FOOTER }}>
      <div className={background}>
        <footer className={footerContainer} {...rest}>
          <h2 className="visually-hidden">{useTranslation('footer::title')}</h2>
          {showTLDRFooter && <TLDRFooter />}
          <nav
            className={footerGrid}
            role="navigation"
            aria-label={useTranslation('footer::nav::label')}
          >
            {footerColumnData
              .filter(({ shouldExclude }) => !shouldExclude)
              .map(({ shouldExclude: _shouldExclude, columnTranslationId, ...restData }) => (
                <FooterColumn
                  key={columnTranslationId}
                  columnTranslationId={columnTranslationId}
                  {...restData}
                />
              ))}
            <div className={classNames(footerCell, languagePicker)}>
              <LanguageSelect />
            </div>
          </nav>
          <div className={classNames(legalDisclaimer, 'ws-text-md')}>
            <div>
              <Markdown source={finalDisclaimerText} />
            </div>
            {showRegulatoryLinks && (
              <div className={regulatoryLinks}>
                <SmartLink href={ciro.url}>
                  <img
                    style={{ width: isFr ? 190 : 140 }}
                    src={isFr ? ciro_fr : ciro_en}
                    alt={ciro.alt}
                  />
                </SmartLink>
                <SmartLink href={cipf.url}>
                  <img style={{ width: 50 }} src={isFr ? cipf_fr : cipf_en} alt={cipf.alt} />
                </SmartLink>
              </div>
            )}
          </div>
        </footer>
      </div>
    </ComponentContextProvider>
  );
};

Footer.propTypes = {
  disclaimerText: PropTypes.node,
  showRegulatoryLinks: PropTypes.bool,
  showTLDRFooter: PropTypes.bool,
};
