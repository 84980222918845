import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { Markdown } from 'src/tapestry/core/markdown';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { translations } from './FormDisclaimer.translations';
import { wrapper_ } from './FormDisclaimer.module.scss';

export const FormDisclaimer = ({ children, className, renderers }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const defaultDisclaimerText = useTranslation('form-disclaimer::text-markdown');
  const disclaimerText = children ?? defaultDisclaimerText;

  return isString(disclaimerText) ? (
    <Markdown
      className={classNames(wrapper_, 'ws-text-sm', 'ws-color-muted', className)}
      source={disclaimerText}
      renderers={renderers}
    />
  ) : (
    disclaimerText
  );
};

FormDisclaimer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
