import React, { useState } from 'react';
import { classNames } from 'src/utils/css';
import { CaretLeft, CaretRight } from 'src/components/common/Icons';
import { FluidText } from 'src/components/common/FluidText';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { MobileNavLink } from '../MobileNavLink/MobileNavLink.component';
import {
  categoryDrawerButton_,
  categoryDrawer_,
  drawerOpen_,
  backButton_,
  primaryCategoryLink_,
  categoryHeading_,
  linkList_,
} from './MobileNavCategoryDrawer.module.scss';

const MobileNavCategoryDrawer = ({ label, categories, isOpen, id, onClick, ...props }) => {
  const [isCategoryDrawerOpen, setCategoryCategoryOpen] = useState(isOpen);

  const handleBackSelect = e => {
    setCategoryCategoryOpen(!isCategoryDrawerOpen);
    if (onClick) onClick(e);
  };

  const handleCategoryViewSelect = e => {
    setCategoryCategoryOpen(true);
    if (onClick) onClick(e);
  };

  return (
    <>
      <button
        className={classNames('ws-text-lg-medium', categoryDrawerButton_)}
        type="button"
        aria-controls={id}
        aria-expanded={isOpen}
        onClick={handleCategoryViewSelect}
        {...props}
      >
        {label}
        <CaretRight size="sm" />
      </button>
      <div
        className={classNames(categoryDrawer_, isOpen && isCategoryDrawerOpen && drawerOpen_)}
        data-testid="subnav-drawer-bg"
        id={id}
      >
        <button
          type="button"
          data-mobilenavindex="backButton"
          aria-controls={id}
          className={classNames(backButton_, `ws-text-lg-medium`)}
          onClick={handleBackSelect}
        >
          <CaretLeft size="sm" />
          {' '}
          {useTranslation('header::nav::back::label')}
        </button>
        {categories.introduction.href && (
          <MobileNavLink
            isSecondary
            href={categories.introduction.href}
            key={categories.introduction.heading}
            className={primaryCategoryLink_}
          >
            {categories.introduction.heading}
          </MobileNavLink>
        )}
        {categories.columns.map(col => (
          <React.Fragment key={`mobile-dropdown-${col.heading}`}>
            <FluidText type="h3" all="ws-eyebrow-xs" className={categoryHeading_}>
              {col.heading}
            </FluidText>
            <ul className={linkList_}>
              <li key={col.label}>
                {col.links.map(link => (
                  <MobileNavLink isSecondary href={link.href} key={link.label}>
                    {link.label}
                  </MobileNavLink>
                ))}
              </li>
            </ul>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export { MobileNavCategoryDrawer };
