import React, { isValidElement, cloneElement } from 'react';
import { PropTypes } from 'prop-types';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from '../Grid';

const PRESETS = {
  twoCell: [
    {
      md: 6,
      middle: true,
    },
    {
      md: {
        width: 5,
        left: 8,
      },
    },
  ],
  twoCellAlt: [
    {
      md: {
        width: 5,
      },
    },
    {
      md: {
        width: 6,
        left: 7,
      },
    },
  ],
  twoCellAltMiddle: [
    {
      md: {
        width: 5,
      },
    },
    {
      md: {
        width: 6,
        left: 7,
        middle: true,
      },
    },
  ],
  twoCellReverseDesktop: [
    {
      md: {
        width: 6,
        left: 7,
        order: 2,
        middle: true,
      },
    },
    {
      md: {
        width: 5,
        left: 1,
        order: 1,
      },
    },
  ],
  twoCellReverseMobile: [
    {
      xs: {
        width: 12,
        order: 2,
      },
      md: {
        width: 6,
        order: 1,
      },
    },
    {
      xs: {
        width: 12,
        order: 1,
      },
      md: {
        width: 5,
        left: 8,
        middle: true,
      },
    },
  ],
  twoCellReverseAll: [
    {
      xs: {
        width: 12,
        order: 2,
      },
      md: {
        width: 6,
        left: 7,
        order: 2,
        middle: true,
      },
    },
    {
      xs: {
        width: 12,
        order: 1,
      },
      md: {
        width: 5,
        left: 1,
        order: 1,
      },
    },
  ],
  twoCellReverseDesktopAlt: [
    {
      md: {
        width: 5,
        left: 8,
        order: 2,
      },
    },
    {
      md: {
        width: 6,
        left: 1,
        order: 1,
      },
    },
  ],
  twoEqualCell: [
    {
      md: {
        width: 6,
      },
    },
    {
      md: {
        width: 6,
        left: 7,
      },
    },
  ],
  twoEqualCellMiddle: [
    {
      md: {
        width: 6,
        middle: true,
      },
    },
    {
      md: {
        width: 6,
        left: 7,
        middle: true,
      },
    },
  ],
  threeCell: [
    {
      all: 12,
    },
    {
      md: 6,
    },
    {
      md: {
        width: 5,
        left: 8,
      },
    },
  ],
  threeCellReverseDesktop: [
    {
      all: 12,
      order: 1,
    },
    {
      md: {
        width: 6,
        left: 7,
        order: 3,
      },
    },
    {
      md: 5,
      order: 2,
    },
  ],
};

export const AutoGrid = ({ preset = 'twoCell', className = '', children }) => {
  const assignChildProps = children.map((child, index) => {
    const cellConfigs = typeof preset === 'string' ? PRESETS[preset] : preset;
    if (cellConfigs.length !== children.length) {
      throw new Error('Incorrect cell count for the current preset.');
    }
    return isValidElement(child) ? cloneElement(child, { ...cellConfigs[index] }) : child;
  });

  return (
    <RefreshGrid
      xs={{ columnGap: 0 }}
      md={{ columnGap: GRID_DEFAULT_CONFIG.gap }}
      className={className}
    >
      {assignChildProps}
    </RefreshGrid>
  );
};

export const AutoGridCell = RefreshCell;

AutoGrid.propTypes = {
  children: PropTypes.node.isRequired,
  preset: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
};
