import { getNextIndexValueFromSearchEngineIndexingValue, getNoindexValueFromSearchEngineIndexingValue } from '../contentful';
import { addProtocolToUrlIfMissing } from '../urls';

const DEFAULT_META_TITLE = 'Wealthsimple: Make your money make more money.';
const DEFAULT_META_DESCRIPTION = `Smart investing products and personalized advice to build long-term wealth. Low-fee managed investing, commission-free trading, and high-interest chequing and savings.`;

// This gets replaced later in DocumentHeadContent. The actual/final URL comes
// from a StaticQuery which can only be run in the context of a component.
export const DEFAULT_META_IMG_URL = `https://www.wealthsimple.com/hotlink-ok/wealthsimple_og_image.jpg`;

export const DEFAULT_METADATA = {
  pageTitle: DEFAULT_META_TITLE,
  pageDescription: DEFAULT_META_DESCRIPTION,
  ogDescription: DEFAULT_META_DESCRIPTION,
  ogImage: DEFAULT_META_IMG_URL,
  ogTitle: DEFAULT_META_TITLE,
  ogType: 'website',
  // Not supported in Contentful model
  fbAppId: '1642151002700015',
  twitterCard: 'summary_large_image',
};

const VALID_METADATA_KEYS = [
  ...Object.keys(DEFAULT_METADATA),
  'ogUrl',
  'twitterTitle',
  'twitterDescription',
];

export const TITLE_ATTRIBUTES = Object.keys(DEFAULT_METADATA).filter(
  key => DEFAULT_METADATA[key] === DEFAULT_META_TITLE
);

export const DESCRIPTION_ATTRIBUTES = Object.keys(DEFAULT_METADATA).filter(
  key => DEFAULT_METADATA[key] === DEFAULT_META_DESCRIPTION
);

export const IMAGE_ATTRIBUTES = ['ogImage'];

export const completeFallbacks = data => {
  const FALLBACKS = {
    ogDescription: ['pageDescription'],
    ogTitle: ['pageTitle'],
  };

  return Object.entries(FALLBACKS).reduce((acc, [fallbackKey, fallbackValues]) => {
    const hasValidValueForKey = (object, key) =>
      Object.prototype.hasOwnProperty.call(object, key) &&
      object[key] !== null &&
      object[key] !== undefined;

    const needsFallback = !hasValidValueForKey(acc, fallbackKey);

    const existingFallbacks = fallbackValues.filter(fallbackValue =>
      hasValidValueForKey(acc, fallbackValue)
    );

    if (!needsFallback || !existingFallbacks.length) {
      return acc;
    }
    return {
      ...acc,
      [fallbackKey]: acc[existingFallbacks[0]],
    };
  }, data);
};

export const removeNullishKeys = object => {
  if (!object || typeof object !== 'object') return {};
  return {
    ...Object.entries(object).reduce((acc, [key, value]) => {
      const validDataTypes = ['string', 'boolean', 'object'];
      return {
        ...acc,
        ...(value !== null && validDataTypes.includes(typeof value) ? { [key]: value } : {}),
      };
    }, {}),
  };
};

export const getMetadataFromContentfulPageMetadata = data => {
  if (data && typeof data === 'object') {
    const { searchEngineIndexing, noIndex, ...restData } = data;
    // Return the flattened object
    return {
      ...restData,
      // Pages/Templates may override the `noIndex` property afterwards before
      // passing metadata to `PageLayout`, but in the object coming from
      // Contentful we can set the value to a naive interpretation of
      // `searchEngineIndexing`.
      // However if `noIndex` is already set here, for example from a template
      // build script, then prefer that value.
      noIndex: noIndex ?? getNoindexValueFromSearchEngineIndexingValue(searchEngineIndexing),
      // These values are nested in Contentful
      pageDescription: data.pageDescription?.pageDescription,
      ogDescription: data.ogDescription?.ogDescription,
      ogImage: data.ogImage?.file?.url,
      twitterDescription: data.twitterDescription?.twitterDescription,
    };
  }
  return {};
};

// Return all valid metadata that isn't nested from the object
const getDirectData = data => {
  if (!data || typeof data !== 'object') return {};
  return Object.entries(data).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(typeof value !== 'object' && VALID_METADATA_KEYS.includes(key) ? { [key]: value } : {}),
    }),
    {}
  );
};

export const getConsolidatedMetadata = (metadataObject, legacyMetadataObject) => {
  const normalizedLegacyMetadata = legacyMetadataObject
    ? {
      pageTitle: legacyMetadataObject.title,
      pageDescription: legacyMetadataObject.description,
      ogImage: legacyMetadataObject.imageUrl,
    }
    : {};

  const consolidatedMetadata =
    // Remove nulls from each set of metadata, then complete fallbacks, then merge into one object
    [
      DEFAULT_METADATA, // defaults, lowest precedence
      normalizedLegacyMetadata, // legacy data
      getMetadataFromContentfulPageMetadata(metadataObject), // data pulled from Contentful metadata object
      getDirectData(metadataObject), // highest precedence
    ]
      .map(object => completeFallbacks(removeNullishKeys(object)))
      .reduce((acc, object) => {
        return {
          ...acc,
          ...object,
        };
      }, {});

  IMAGE_ATTRIBUTES.forEach(attr => {
    if (consolidatedMetadata[attr]) {
      consolidatedMetadata[attr] = addProtocolToUrlIfMissing(consolidatedMetadata[attr]);
    }
  });

  return consolidatedMetadata;
};

const ARTICLE_TITLE_SUFFIX = '| Wealthsimple';
export const transformArticleLegacyMetaTitle = title => {
  if (title) {
    if (title.endsWith(ARTICLE_TITLE_SUFFIX)) {
      return title;
    }

    return [title, ARTICLE_TITLE_SUFFIX].join(' ');
  }

  return undefined;
};

export const getNextMetadataFromContentfulPage = page => {
  if (!page) return null;
  if (!page?.metadata && !page?.metaObject) return null;

  // Defaults should NOT be handled in here, but rather in a layout.js or page.js
  const {
    title,
    description,
    ogTitle,
    ogDescription,
    ogImage,
    noIndex,
    searchEngineIndexing,
    twitterTitle,
    twitterDescription,
  } = removeNullishKeys(page.metadata ?? page.metaObject);

  // Docs: https://nextjs.org/docs/app/api-reference/functions/generate-metadata

  const nextMetadata = {
    title,
    description,
    robots: {
      // Pages/Layouts may override the `index` property afterwards before
      // returning in `generateMetadata`, but in the object coming from
      // Contentful we can set the value to a naive interpretation of
      // `searchEngineIndexing` or the legacy `noIndex`.
      index: !noIndex ?? getNextIndexValueFromSearchEngineIndexingValue(searchEngineIndexing),
    },
  };

  if (ogTitle || ogDescription || ogImage) {
    nextMetadata.openGraph = {};

    if (ogTitle) nextMetadata.openGraph.title = ogTitle;
    if (ogDescription) nextMetadata.openGraph.description = ogDescription;
    if (ogImage && ogImage?.url) nextMetadata.openGraph.images = [{ url: ogImage.url }];
  }

  if (twitterTitle || twitterDescription) {
    nextMetadata.twitter = {};

    if (twitterTitle) nextMetadata.twitter.title = twitterTitle;
    if (twitterDescription) nextMetadata.twitter.description = twitterDescription;
  }

  return nextMetadata;
};
