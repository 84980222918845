import React from 'react';
import PropTypes from 'prop-types';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { AnimatedScrollBlock } from './animated-scroll-block.component';

const A11yAnimatedScrollBlock = ({ children, fallback = null, ...props }) => {
  const { prefersReducedMotion } = useMotionOptOutContext();

  if (prefersReducedMotion && fallback !== null) {
    return <div {...props}>{fallback}</div>;
  }

  if (prefersReducedMotion) {
    return <div {...props}>{children}</div>;
  }

  return <AnimatedScrollBlock {...props}>{children}</AnimatedScrollBlock>;
};

A11yAnimatedScrollBlock.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
};

export { A11yAnimatedScrollBlock };
