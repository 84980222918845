/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const WealthsimpleBrand = ({
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="#32302F" d="M23.054 7.358a12.159 12.159 0 0 0-2.59-3.827A12.281 12.281 0 0 0 16.649.939C15.204.313 13.66 0 12.008 0c-1.65 0-3.2.313-4.653.94A12.239 12.239 0 0 0 .939 7.358C.313 8.808 0 10.359 0 12.012c0 1.652.313 3.198.939 4.64a12.321 12.321 0 0 0 2.59 3.827 12.002 12.002 0 0 0 3.826 2.592c1.45.618 3.001.928 4.653.928 1.648 0 3.197-.31 4.641-.928a11.895 11.895 0 0 0 3.815-2.592 12.401 12.401 0 0 0 2.59-3.827c.626-1.445.939-2.991.939-4.64 0-1.65-.313-3.204-.939-4.655Zm-2.476.548c-.366.168-.71.509-1.031 1.02-.321.512-.71 1.264-1.168 2.26l-3.23 6.968h-.16l-2.68-6.326-3.185 6.326h-.16l-3.529-7.838c-.321-.719-.634-1.27-.939-1.65-.304-.383-.656-.634-1.053-.757v-.137h5.315v.137a1.288 1.288 0 0 0-.459.332.685.685 0 0 0-.159.448c0 .075.011.159.034.251.022.093.058.19.103.3l2.27 5.294 1.558-3.072-.824-1.993c-.246-.613-.47-1.01-.676-1.191a1.593 1.593 0 0 0-.769-.367v-.137h5.43v.137c-.55.093-.824.383-.824.873 0 .123.033.293.103.517.07.22.148.439.24.654l1.764 4.355.413-.847c.322-.67.576-1.305.769-1.903.19-.596.287-1.186.287-1.764 0-.534-.103-.956-.31-1.261-.206-.305-.508-.512-.905-.618V7.78h3.78v.126h-.005Z" /></svg>;
};
WealthsimpleBrand.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default WealthsimpleBrand;