export const translations = {
  'en-ca': {
    'cookie-banner::disclosure-text': `Your privacy matters. We use cookies to analyze web traffic and personalize your experience. For more information, visit our [Cookie\u00a0Policy](/legal/cookie-policy).`,
    'cookie-banner::cta::allow': 'Allow all',
    'cookie-banner::cta::deny': 'Only allow necessary',
    'cookie-banner::close-label': 'Close',
  },
  'fr-ca': {
    'cookie-banner::disclosure-text': `La protection de votre vie privée est une de nos priorités. Nous utilisons des cookies pour analyser le trafic Web et personnaliser votre expérience. Pour en savoir plus, consultez notre [politique sur l'utilisation de cookies](/legal/cookie-policy).`,
    'cookie-banner::cta::allow': 'Tout autoriser',
    'cookie-banner::cta::deny': 'Seulement les cookies nécessaires',
    'cookie-banner::close-label': 'Fermer',
  },
};
