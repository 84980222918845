import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { GRID_DEFAULT_CONFIG } from './RefreshGrid.constants';
import { base_ } from './RefreshGrid.module.scss';

const TAILWIND_GAP_SIZES = [0, 16, 32]; // px

// Generate the inline style properties to be applied based on the config
const convertBreakpointConfigToClassNames = (mediaConfig, breakpoint) => {
  const {
    columns,
    rows,
    gap,
    columnGap,
    rowGap,
  } = mediaConfig;

  let bpClassNames = [];

  // tailwind classes
  if (columns) bpClassNames.push(`grid-cols-${columns}`);
  if (rows) bpClassNames.push(`grid-rows-${rows}`);
  if (TAILWIND_GAP_SIZES.includes(gap)) bpClassNames.push(`gap-${gap}`);
  if (TAILWIND_GAP_SIZES.includes(columnGap)) bpClassNames.push(`gap-x-${columnGap}`);
  if (TAILWIND_GAP_SIZES.includes(rowGap)) bpClassNames.push(`gap-y-${rowGap}`);
  if (gap === GRID_DEFAULT_CONFIG.gap) bpClassNames.push('gap-ws-default');
  if (columnGap === GRID_DEFAULT_CONFIG.gap) bpClassNames.push('gap-x-ws-default');
  if (rowGap === GRID_DEFAULT_CONFIG.gap) bpClassNames.push('gap-y-ws-default');

  // add tailwind breakpoint prefixes
  if (breakpoint) {
    bpClassNames = bpClassNames.map(className => ([breakpoint, className].join(':')));
  }

  return bpClassNames;
};

const processBreakpoint = (config, breakpoint) => {
  return config ? convertBreakpointConfigToClassNames(config, breakpoint) : [];
};

export const RefreshGrid = forwardRef(function RefreshGrid(
  { className, children, xs, sm, md, lg, xl, all, as: GridElement = 'div', ...rest },
  ref
) {
  const allWithDefaults = { ...GRID_DEFAULT_CONFIG, ...(all ?? {}), ...(xs ?? {}) };
  const gridClassNames = [
    processBreakpoint(allWithDefaults, null),
    processBreakpoint(sm, 'sm'),
    processBreakpoint(md, 'md'),
    processBreakpoint(lg, 'lg'),
    processBreakpoint(xl, 'xl'),
  ].flat();

  return (
    <GridElement
      ref={ref}
      className={classNames(base_, gridClassNames, className)}
      {...rest}
    >
      {children}
    </GridElement>
  );
});

const breakpointShape = {
  columns: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  rows: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  gap: PropTypes.oneOf([...TAILWIND_GAP_SIZES, GRID_DEFAULT_CONFIG.gap]),
  columnGap: PropTypes.oneOf([...TAILWIND_GAP_SIZES, GRID_DEFAULT_CONFIG.gap]),
  rowGap: PropTypes.oneOf([...TAILWIND_GAP_SIZES, GRID_DEFAULT_CONFIG.gap]),
};

RefreshGrid.propTypes = {
  /**
   * Only instances of `RefreshCell` or components that return a `RefreshCell` as their top level
   * */
  children: PropTypes.node.isRequired,
  /**
   * Configuration to apply to all breakpoints.
   *
   * Numbers can be used as a shorthand for the `columns` property of the config object.
   *
   * e.g., `12` and `{ columns: 12 }` are equivalent.
   *
   * In general, you should not need to modify the number of grid columns at all.
   */
  all: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `xs` breakpoint and up. */
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `sm` breakpoint and up. */
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `md` breakpoint and up. */
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `lg` breakpoint and up. */
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `xl` breakpoint. */
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.shape(breakpointShape)]),
  /** The HTML element type to be returned */
  as: PropTypes.node,
  className: PropTypes.string,
};
