export const translations = {
  'en-ca': {
    'form-input-field::password::show-password':
      'Show password as plain text. Note: this will visually expose your password on the screen.',
    'form-input-field::password::hide-password': 'Hide password',
    'form-input-field::required': '*Required',
  },
  'fr-ca': {
    'form-input-field::password::show-password':
      "Afficher le mot de passe sous forme de texte brut. Remarque: cela exposera visuellement votre mot de passe à l'écran.",
    'form-input-field::password::hide-password': 'Masquer le mot de passe',
    'form-input-field::required': '*Requis',
  },
};
