'use client';

import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { toObjectId } from '@stackbit/annotations';
import { useHandleContentChange } from 'src/utils/netlify-create/event-handlers';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { Chatbot } from 'src/components/common/Chatbot';
import { CookieBanner } from 'src/components/common/CookieBanner';
import { DocumentHeadContent } from 'src/components/common/DocumentHeadContent';
import { Header, HeaderContextProvider } from '../Header';
import { Footer } from '../Footer';
import 'src/styles/globals.scss';

const PageLayout = ({
  bodyClassName,
  breadcrumbs,
  customHeader,
  customFooter,
  children,
  metadata,
  notificationBanner,
  postFooter,
  withChatbot = false,
  entryId,
}) => {
  const { isEn } = useTranslationContext();
  useHandleContentChange();

  return (
    <>
      <HeaderContextProvider>
        <DocumentHeadContent
          bodyClassName={bodyClassName}
          metadata={metadata}
          breadcrumbs={breadcrumbs}
        />
        <Header {...customHeader?.props} notificationBanner={notificationBanner} />
        {/* TODO: consolidate chatbot eligibility logic (english-only) into a shared utility */}
        <CookieBanner withChatbot={withChatbot && isEn} />
        {withChatbot && <Chatbot handle={isString(withChatbot) ? withChatbot : undefined} />}
      </HeaderContextProvider>
      <main id="main" tabIndex="-1" {...toObjectId(entryId)}>
        {children}
      </main>
      {customFooter ?? <Footer />}
      {postFooter}
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  customHeader: PropTypes.node,
  notificationBanner: PropTypes.node,
  withChatbot: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  entryId: PropTypes.string,
};

export { PageLayout };
