const SEARCH_ENGINE_INDEXING_FIELD_VALUES = {
  'No Index': true,
  Index: false,
};

const GLOBAL_DEFAULT_NOINDEX_VALUE = false;

const getNoindexValueFromSearchEngineIndexingValue = (
  fieldValue,
  defaultValue = GLOBAL_DEFAULT_NOINDEX_VALUE
) => SEARCH_ENGINE_INDEXING_FIELD_VALUES[fieldValue] ?? defaultValue;

const getNextIndexValueFromSearchEngineIndexingValue = (
  fieldValue,
  defaultValue = !GLOBAL_DEFAULT_NOINDEX_VALUE
) => !SEARCH_ENGINE_INDEXING_FIELD_VALUES[fieldValue] ?? defaultValue;

module.exports = { getNoindexValueFromSearchEngineIndexingValue, getNextIndexValueFromSearchEngineIndexingValue };
