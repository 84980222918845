export const translations = {
  'en-ca': {
    'form-validators::dictionary': {
      email: 'Email',
      password: 'Password',
      phone: 'Phone number',
      first_name: 'First Name',
      last_name: 'Last Name',
    },
    'form-validators::required': '{{fieldName}} is a required field',
    'form-validators::minLength': 'The minimum length is {{min}} characters',
    'form-validators::email': 'Please enter a valid email in the format example@domain.com',
    'form-validators::phoneNumber': 'Please enter a valid 10-digit phone number',
    'form-validators::passwordNotCompromised': 'This password is publicly known to be compromised',
    'form-validators::dollarAmount': 'Please enter a valid dollar amount (e.g. $25,000).',
  },
  'fr-ca': {
    'form-validators::dictionary': {
      email: 'courriel',
      password: 'mot de passe',
      phone: 'Téléphone',
      first_name: 'Prénom',
      last_name: 'Nom de famille',
    },
    'form-validators::required': 'Le champ {{fieldName}} est obligatoire',
    'form-validators::minLength': 'La longueur minimale est de {{min}} caractères',
    'form-validators::email':
      'Veuillez entrer une adresse courriel valide dans le format suivant : exemple@domaine.com',
    'form-validators::phoneNumber': 'Veuillez entrer un numéro de téléphone valide à 10 chiffres',
    'form-validators::passwordNotCompromised':
      'Ce mot de passe est connu publiquement comme étant compromis',
    'form-validators::dollarAmount':
      'Veuillez entrer un montant en dollars valide (ex.: 25 000 $).',
  },
};
