'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import { stubAnalyticsMethod } from 'lib/segment';
import { getSessionParams, persistSessionParams } from 'src/utils/session-params';

// Based on the official NextJS example implementation for Segment page events
// https://github.com/vercel/next.js/blob/abff797e92012abed0260e4e0db67abe222be06c/examples/with-segment-analytics/components/analytics.tsx

// debounce is to prevent double events running in React's strict mode,
// which is what we get in development (the below useEffect runs twice). With
// the options as configured, we fire the first call, and throw away subsequent
// calls within the timeout period.
const _trackPageEvent = debounce((title, options) => {
  window.analytics.page(title, options);
}, 100, { leading: true, trailing: false });

export const TrackPageEvents = () => {
  const path = usePathname();
  const search = useSearchParams().toString();

  useEffect(() => {
    persistSessionParams();

    if (!window?.analytics?.page) {
      // Stub analytics queue for page calls only
      // The Segment snippet also does this, but if we're here then it hasn't run yet
      stubAnalyticsMethod('page');
    }

    // In the future we could potentially get this from state, but since there
    // are hard reloads across Gatsby and Next, the stored session data is the
    // only reliable place to get the previous path.
    const { previous_path: prevPath } = getSessionParams();
    const prevLocationFromSession = isString(prevPath) ? new URL(prevPath, window.location).toString() : undefined;

    _trackPageEvent(document.title, {
      title: document.title,
      path,
      url: window.location.href,
      search,
      ganalytics_client_id: window.clientId,
      delocalized_path: path.replace(/^\/[a-z]{2}-[a-z]{2}/, ''),
      referrer: prevLocationFromSession || document.referrer,
    });
  }, [path, search]);

  return null;
};
