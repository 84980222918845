/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Gift = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M6.5 2C6.10217 2 5.72064 2.15804 5.43934 2.43934C5.15804 2.72064 5 3.10217 5 3.5C5 3.89783 5.15804 4.27936 5.43934 4.56066C5.72064 4.84196 6.10217 5 6.5 5H9.65402C9.53195 4.68061 9.37741 4.32942 9.18628 3.98192C8.55732 2.83834 7.6939 2 6.5 2ZM10 7V10H2V7H10ZM3.33772 5C3.11743 4.53557 3 4.02384 3 3.5C3 2.57175 3.36874 1.6815 4.02512 1.02512C4.6815 0.368744 5.57175 0 6.5 0C8.8061 0 10.1927 1.66166 10.9387 3.01808C10.9595 3.0559 10.9799 3.09368 11 3.13141C11.0201 3.09368 11.0405 3.0559 11.0613 3.01808C11.8073 1.66166 13.1939 0 15.5 0C16.4282 0 17.3185 0.368753 17.9749 1.02508C18.6312 1.68144 19 2.57174 19 3.5C19 4.02384 18.8826 4.53558 18.6623 5H21C21.5523 5 22 5.44772 22 6V11C22 11.5523 21.5523 12 21 12H20V21C20 21.5523 19.5523 22 19 22H3C2.44772 22 2 21.5523 2 21V12H1C0.447715 12 0 11.5523 0 11V6C0 5.44772 0.447715 5 1 5H3.33772ZM4 12V20H10V12H4ZM12 12V20H18V12H12ZM20 10V7H12V10H20ZM16.5607 4.56062C16.842 4.27931 17 3.89779 17 3.5C17 3.10221 16.842 2.72069 16.5607 2.43938C16.5607 2.43936 16.5608 2.43941 16.5607 2.43938M16.5607 2.43938C16.2794 2.15807 15.8977 2 15.5 2C14.3061 2 13.4427 2.83834 12.8137 3.98192C12.6226 4.32942 12.4681 4.68061 12.346 5H15.5C15.8977 5 16.2794 4.84193 16.5607 4.56062C16.5608 4.56059 16.5607 4.56064 16.5607 4.56062" fill={color} /></svg>;
};
Gift.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Gift;