'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { EVENT_NAMES } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout';
import { getLinkThemeClass, ButtonLabel } from 'src/components/common/CTAButton';
import {
  primaryButton,
  secondaryButton,
  sizeSm,
  sizeMd,
  sizeLg,
  sizeXl,
  sizeXxl,
} from 'src/components/common/CTAButton/CTAButton.module.scss';

const CTALinkButton = ({
  variant = 'primary',
  size,
  onClick,
  children,
  className,
  href,
  theme = 'none',
  icon,
  iconPosition = 'right',
  ...rest
}) => {
  const { shouldUseDarkTheme } = useComponentContext();
  const themeClassName = getLinkThemeClass(theme, shouldUseDarkTheme);

  const finalSize = size ?? (variant === 'secondary' ? 'md' : 'xxl');

  return (
    <SmartLink
      className={classNames(
        BUTTON_LINK_VARIANT_TO_CLASS[variant],
        BUTTON_LINK_SIZE_TO_CLASS[finalSize],
        themeClassName,
        className
      )}
      href={href}
      onClick={onClick}
      eventData={{
        name: EVENT_NAMES.PRIMARY_CTA_CLICKED,
        label: children,
      }}
      {...rest}
    >
      <ButtonLabel icon={icon} iconPosition={iconPosition}>{children}</ButtonLabel>
    </SmartLink>
  );
};

const BUTTON_LINK_VARIANT_TO_CLASS = {
  primary: primaryButton,
  secondary: secondaryButton,
};

const BUTTON_LINK_SIZE_TO_CLASS = {
  sm: sizeSm,
  md: sizeMd,
  lg: sizeLg,
  xl: sizeXl,
  xxl: sizeXxl,
};

CTALinkButton.propTypes = {
  variant: PropTypes.oneOf(Object.keys(BUTTON_LINK_VARIANT_TO_CLASS)),
  size: PropTypes.oneOf(Object.keys(BUTTON_LINK_SIZE_TO_CLASS)),
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['none', 'dark', 'light']),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

export { CTALinkButton, BUTTON_LINK_VARIANT_TO_CLASS, BUTTON_LINK_SIZE_TO_CLASS };
