import { NAV_HEIGHT_OFFSET, TOOLTIP_ALIGNMENT } from './tooltip.constants';

export const checkCollisions = (
  horizontalAlign,
  verticalAlign,
  wrapperRect,
  tooltipRect,
  referenceRect,
) => {
  let _referenceRect = referenceRect;

  // Define a default reference rectangle if none provided or improperly passed
  if (!referenceRect || !referenceRect.width || !referenceRect.height) {
    _referenceRect = {
      x: 0,
      y: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let finalHorizontalAlignment = horizontalAlign;
  let finalVerticalAlignment = verticalAlign;
  const horizontalCenterX = wrapperRect.x + (wrapperRect.width / 2) - (tooltipRect.width / 2);

  // Horizontal collision detection and adjustment
  if (horizontalAlign === TOOLTIP_ALIGNMENT.LEFT && (wrapperRect.x + tooltipRect.width > _referenceRect.width)) {
    finalHorizontalAlignment = horizontalCenterX + tooltipRect.width <= _referenceRect.width ? TOOLTIP_ALIGNMENT.CENTER : TOOLTIP_ALIGNMENT.RIGHT;
  } else if (horizontalAlign === TOOLTIP_ALIGNMENT.RIGHT && (wrapperRect.x - tooltipRect.width < _referenceRect.x)) {
    finalHorizontalAlignment = horizontalCenterX >= _referenceRect.x ? TOOLTIP_ALIGNMENT.CENTER : TOOLTIP_ALIGNMENT.LEFT;
  } else if (horizontalAlign === TOOLTIP_ALIGNMENT.CENTER) {
    if (horizontalCenterX < _referenceRect.x || horizontalCenterX + tooltipRect.width > _referenceRect.width) {
      finalHorizontalAlignment = horizontalCenterX < _referenceRect.x ? TOOLTIP_ALIGNMENT.RIGHT : TOOLTIP_ALIGNMENT.LEFT;
    }
  }

  switch (verticalAlign) {
    case TOOLTIP_ALIGNMENT.TOP:
      if (
        (_referenceRect.y <= NAV_HEIGHT_OFFSET &&
          wrapperRect.y - tooltipRect.height - NAV_HEIGHT_OFFSET < _referenceRect.y) ||
        wrapperRect.y - tooltipRect.height < _referenceRect.y
      ) {
        finalVerticalAlignment = TOOLTIP_ALIGNMENT.BOTTOM;
      }
      break;
    case TOOLTIP_ALIGNMENT.BOTTOM:
      if (wrapperRect.y + tooltipRect.height >= _referenceRect.height) {
        finalVerticalAlignment = TOOLTIP_ALIGNMENT.TOP;
      }
      break;
    default:
      break;
  }

  return {
    finalHorizontalAlignment,
    finalVerticalAlignment,
  };
};

export const findOverflowNotVisibleParent = element => {
  const overflowNotVisible = el => {
    return getComputedStyle(el).overflow !== 'visible';
  };

  if (overflowNotVisible(element) && element !== document.body) {
    return element;
  }

  return element.parentElement === document.body
    ? null
    : findOverflowNotVisibleParent(element.parentElement);
};
