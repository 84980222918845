import { getSrc, getImage } from 'gatsby-plugin-image';

export const CODEC_TYPE_H265 = 'video/mp4; codecs="hvc1"';
export const CODEC_TYPE_WEBM = 'video/webm';
export const CODEC_TYPE_H264 = 'video/mp4';

// creates an object for easy Media node lookup
export const createMediaMap = media => {
  return media.reduce((acc, node) => {
    acc[node.mediaTitle] = node;
    return acc;
  }, {});
};

/**
 * @deprecated Used only in Gatsby. For Next.js, use createMediaMapFromContent instead.
 */
export const createMediaMapFromContentGatsby = content => {
  const filtered = content.filter(item => {
    return item.__typename === 'ContentfulMedia';
  });
  return createMediaMap(filtered);
};

export const createMediaMapFromContent = content => {
  const filtered = content.filter(item => {
    return item.__typename === 'Media';
  });
  return createMediaMap(filtered);
};

export const createGatsbyImageMap = imgData => {
  const temp = {};
  imgData.forEach(node => {
    const imgTitle = node.title;
    temp[imgTitle] = {};
    temp[imgTitle].gatsbyImageData = node.gatsbyImageData;
    temp[imgTitle].title = imgTitle;
    temp[imgTitle].description = node.description ?? '';
  });
  return temp;
};

// Using media object from Contentful, or the transformed version, figure out if the media is a video
export const isMediaWithVideoSource = media => {
  const { webm, h265, h264, h265Src, webmSrc, h264Src } = media || {};
  const videoSources = [h265, h264, webm, h265Src, webmSrc, h264Src];

  return videoSources.some(videoSrc => !!videoSrc);
};

/**
 * @deprecated Used only in Gatsby. For Next.js, use getMediaPropsFromContentfulMedia instead.
 */
export const getMediaPropsFromGatsbyContentfulMedia = (media = {}) => {
  const {
    image,
    videoPoster,
    autoPlayVideo,
    shouldLoopVideo,
    backgroundColor,
    h265,
    webm,
    h264,
    caption,
  } = media;

  return {
    gatsbyImageData: image?.gatsbyImageData,
    posterSrc: getSrc(videoPoster?.gatsbyImageData ?? image?.gatsbyImageData),
    // eslint-disable-next-line no-unsafe-optional-chaining
    aspectRatio: image?.gatsbyImageData?.width / image?.gatsbyImageData?.height,
    alt: image?.description ?? '',
    autoPlayVideo,
    backgroundColor,
    shouldLoopVideo: shouldLoopVideo ?? true, // if not provided, default to true
    h265Src: h265?.file?.url,
    webmSrc: webm?.file?.url,
    h264Src: h264?.file?.url,
    caption: caption?.caption,
  };
};

export const getMediaPropsFromContentfulMedia = media => {
  if (!media) return null;

  const {
    // eslint-disable-next-line no-unused-vars
    image: { __typename, url: imageUrl, width, height, description, ...restImage },
    autoPlayVideo,
    shouldLoopVideo,
    backgroundColor,
    h265,
    webm,
    h264,
    caption,
  } = media;

  const videoPosterUrl = media?.videoPoster ? media.videoPoster?.url : null;

  return {
    imageData: {
      src: imageUrl,
      width,
      height,
      quality: 90,
      ...restImage,
    },
    posterSrc: videoPosterUrl,
    aspectRatio: width / height,
    alt: description ?? '',
    autoPlayVideo,
    backgroundColor,
    shouldLoopVideo: shouldLoopVideo ?? true, // if not provided, default to true
    h265Src: h265?.url,
    webmSrc: webm?.url,
    h264Src: h264?.url,
    caption,
  };
};

export const getAspectRatio = (image = {}) => {
  const gatsbyImageData = getImage(image);
  return `${gatsbyImageData?.height} / ${gatsbyImageData?.width}`;
};

const mapMediaAlignToObjectPosition = alignment => {
  switch (alignment) {
    case 'center':
      return '50% 50%';
    case 'flex-start':
      return '50% top';
    case 'flex-end':
      return '50% bottom';
    default:
      return '50% 50%';
  }
};

export const getMediaBackgroundStyles = mediaBackground => {
  if (!mediaBackground) {
    return {};
  }

  const { alignItems: mediaAlignItems = null, ...restMediaBackground } = mediaBackground;

  return {
    ...restMediaBackground,
    objectPosition: mediaAlignItems
      ? mapMediaAlignToObjectPosition(mediaAlignItems)
      : null,
  };
};

export const getPromotionalSectionBackgroundStyles = mediaBackground => {
  if (!mediaBackground) {
    return {};
  }

  const { alignItems: mediaAlignItems = null, ...restMediaBackground } = mediaBackground;

  return {
    ...restMediaBackground,
    alignSelf: mediaAlignItems,
  };
};
