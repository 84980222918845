import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { trackAnalyticsEvent, EVENT_NAMES } from 'src/services/analytics-service';
import { Checkmark } from 'src/components/common/Icons';
import {
  required as getRequiredError,
  email as getEmailError,
  phoneNumber as getPhoneNumberError,
} from 'src/components/common/FormValidationErrorMessage';
import { useComponentContext } from 'src/components/layout';
import { FormInputField } from 'src/components/common/FormInputField';
import { FormDisclaimer } from 'src/components/common/FormDisclaimer';
import { CTAButton } from 'src/components/common/CTAButton';
import { translations } from './OneFieldSubscribeForm.translations';
import {
  flexWrapper_,
  inputWrapper_,
  submitButton_,
  successMessage_,
} from './OneFieldSubscribeForm.module.scss';

const OneFieldSubscribeForm = React.forwardRef(function OneFieldSubscribeForm(
  {
    id = 'oneFieldForm',
    fieldType = 'email',
    inputLabel,
    className,
    formClassName,
    onSubmitAction,
    onSuccess,
    onSubmitError,
    successMessage,
    buttonLabel,
    disclaimerText,
    disclaimerClassName,
  },
  ref
) {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  // TRANSLATIONS
  const buttonLabelFallback = useTranslation('one-field-form::submit::label');
  const submitFailureMessageFallback = useTranslation('one-field-form::submit::failure');
  const successMessageFallback = useTranslation('one-field-form::submit::success');
  const telephoneLabel = useTranslation('one-field-form::tel::label');
  const emailLabel = useTranslation('one-field-form::email::label');
  const inputLabelByFieldType = useTranslation(`one-field-form::${fieldType}::label`);

  const isPhoneNumberForm = fieldType === 'tel';
  const fieldName = isPhoneNumberForm ? telephoneLabel : emailLabel;
  const validateFormatFunc = isPhoneNumberForm ? getPhoneNumberError : getEmailError;

  const [userValue, setUserValue] = useState('');
  const [fieldValidationError, setFieldValidationError] = useState('');
  const [formError, setFormError] = useState('');
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const componentContext = useComponentContext();

  const resetForm = () => {
    setIsSuccessfullySubmitted(false);
    setUserValue('');
  };

  const validateRequired = () => {
    let error = '';

    error = getRequiredError(fieldName)(userValue);

    setFieldValidationError(error);
    return error;
  };

  const validateFormat = () => {
    let error = '';
    error = validateFormatFunc(userValue);
    setFieldValidationError(error);
    return error;
  };

  const handleChange = (e, userInputValue) => {
    setUserValue(userInputValue);
  };

  const handleBlur = () => {
    validateFormat();
  };

  const handleFocus = () => {
    // Reset form on refocus after submit
    if (isSuccessfullySubmitted) {
      resetForm();
    }
    setFieldValidationError('');
    setFormError('');
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setFieldValidationError('');
    const error = validateFormat() || validateRequired();

    // If we have a field error or if they just successfully submitted without
    // updating their info, we don't want to submit again
    if (error || isSuccessfullySubmitted) return;

    setIsSubmitting(true);

    const params = {
      [fieldType]: userValue,
    };

    try {
      const result = await onSubmitAction(params);
      setFieldValidationError('');
      setIsSuccessfullySubmitted(true);
      trackAnalyticsEvent(EVENT_NAMES.FORM_SUBMIT_SUCCESS, {}, componentContext);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (submitError) {
      trackAnalyticsEvent(EVENT_NAMES.FORM_SUBMIT_FAILURE, {}, componentContext);
      if (onSubmitError) {
        onSubmitError(submitError, { setFormError, defaultMessage: submitFailureMessageFallback });
      } else {
        setFormError(submitFailureMessageFallback);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div ref={ref} className={classNames(className)}>
      <form
        id={id}
        className={classNames(flexWrapper_, formClassName)}
        onSubmit={handleSubmit}
        noValidate
      >
        <div className={inputWrapper_}>
          <FormInputField
            id={`${id}-${fieldType}`}
            type={fieldType}
            label={inputLabel || inputLabelByFieldType}
            value={userValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            showError={Boolean(fieldValidationError || formError)}
            showInvalid={Boolean(fieldValidationError || formError)}
            error={fieldValidationError || formError}
            message={
              isSuccessfullySubmitted && (
                <span className={classNames('ws-color-success', successMessage_)}>
                  {successMessage ?? successMessageFallback}
                  {' '}
                  <Checkmark size="sm" />
                </span>
              )
            }
          />
        </div>
        <CTAButton
          className={submitButton_}
          size="xxl"
          type="submit"
          disabled={isSubmitting || isSuccessfullySubmitted}
        >
          {buttonLabel || buttonLabelFallback}
        </CTAButton>
      </form>
      {disclaimerText && (
        <FormDisclaimer className={disclaimerClassName}>{disclaimerText}</FormDisclaimer>
      )}
    </div>
  );
});

OneFieldSubscribeForm.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  formClassName: PropTypes.string,
  fieldType: PropTypes.oneOf(['email', 'tel']),
  onSubmitAction: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
  disclaimerText: FormDisclaimer.propTypes.children,
  disclaimerClassName: FormDisclaimer.propTypes.className,
};

export { OneFieldSubscribeForm };
