import { DEFAULT_FORM_DISCLAIMER_EN, DEFAULT_FORM_DISCLAIMER_FR } from './FormDisclaimer.constants';

export const translations = {
  'en-ca': {
    'form-disclaimer::text-markdown': DEFAULT_FORM_DISCLAIMER_EN,
  },
  'fr-ca': {
    'form-disclaimer::text-markdown': DEFAULT_FORM_DISCLAIMER_FR,
  },
};
