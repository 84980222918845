import React from 'react';
import PropTypes from 'prop-types';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { translations } from './FormValidationErrorMessage.translations';

export const FormValidationErrorMessage = ({ type, interpolations, customMessage }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const rawMessage = useTranslation(`form-validators::${type}`);
  const dictionary = useTranslation('form-validators::dictionary');

  if (!rawMessage && !customMessage) return null;

  let finalMessage;

  if (customMessage) {
    finalMessage = customMessage;
  } else if (interpolations) {
    finalMessage = Object.keys(interpolations).map(key => {
      const replacement =
        dictionary[String(interpolations[key]).toLowerCase()] ?? interpolations[key];
      return rawMessage.replace(`{{${key}}}`, replacement);
    });
  } else {
    finalMessage = rawMessage;
  }

  return <>{finalMessage}</>;
};

FormValidationErrorMessage.propTypes = {
  type: PropTypes.string.isRequired,
  interpolations: PropTypes.shape({}),
};
