'use client';

import React, { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { getIsDarkThemeFromBackgroundHexColor } from 'src/utils/colors/color-conversion';
import { colorBackground } from 'src/styles/exports.module.scss';
import {
  section_,
  background_,
  wrapperBackground_,
  sectionVerticalSpacingTight_,
  sectionCollapse_,
  sectionCollapseTop_,
  sectionCollapseBottom_,
  sectionContent_,
  hasShadowAndSqueeze_,
  hasShadow_,
  content_,
  container_,
  squeezeContainer_,
  squeezeContainerWithBorderRadius_,
  squeezeContainerHasShadow_,
  scrollTrigger_,
  pullUpBg_,
} from './Section.module.scss';
import { SectionSqueezeAnimation } from './SectionSqueezeAnimation/SectionSqueezeAnimation';
import { ANIMATION_TO_CLASS } from './helpers/section-class-maps';

const Section = forwardRef(function Section(
  {
    background,
    wrapperBackground,
    colorScheme,
    zIndex,
    className,
    contentClassName,
    forceFullWidth = false,
    sectionIndex,
    verticalSpacing,
    verticalSpacingCollapse = 'none',
    children,
    animation,
    animationBreakpoints,
    shadow = false,
    hasBorderRadiusBottom = false,
    beforeContent,
    afterContent,
    darkTheme = false,
    ...props
  },
  sectionRef
) {
  const ref = useRef();
  const contentRef = useRef();
  const { prefersReducedMotion } = useMotionOptOutContext();
  const containerClassName = forceFullWidth ? '' : container_;
  const { style = {}, ...restProps } = props;

  const hasSqueezeAnimation = !prefersReducedMotion && animation && animation.includes('squeeze');
  const hasSqueezeContentAnimation =
    !prefersReducedMotion &&
    animation === 'squeeze-content-do-not-use-or-you-will-be-haunted-by-spooky-ghosts';

  // Backgrounds
  const shouldUseWrapperBackground = hasSqueezeAnimation || hasBorderRadiusBottom;
  const shouldExtendBackgroundUpward = sectionIndex === 0;

  const sectionStyleProps = {
    '--background': background || colorBackground,
    ...(!hasSqueezeContentAnimation ? { '--section-wrapper-background': wrapperBackground } : {}),
    ...(zIndex ? { zIndex } : {}),
    ...style,
  };

  const shouldUseDarkTheme = darkTheme || getIsDarkThemeFromBackgroundHexColor(background);

  const scrollDetectionId = `section-${sectionIndex}-scroll-bottom`;

  const isSqueezeAnimation = animationName =>
    animationName === 'squeeze' || animationName === 'squeeze-in-out';

  const sectionComponent = (
    <section
      ref={sectionRef}
      className={classNames(
        'Section',
        section_,
        verticalSpacing === 'tight' && sectionVerticalSpacingTight_,
        background_,
        shouldUseWrapperBackground && wrapperBackground_,
        hasSqueezeAnimation && shadow && hasShadowAndSqueeze_,
        !hasSqueezeAnimation && !hasBorderRadiusBottom && shadow && hasShadow_,
        ANIMATION_TO_CLASS[animation],
        shouldExtendBackgroundUpward && pullUpBg_,
        className
      )}
      id={`section-${sectionIndex}`}
      style={sectionStyleProps}
      {...restProps}
    >
      {beforeContent}
      {!prefersReducedMotion && isSqueezeAnimation(animation) && (
        <SectionSqueezeAnimation
          ref={ref}
          trigger={`#${scrollDetectionId}`}
          id={`section-squeeze=${sectionIndex}`}
          animationName={animation}
          breakpoints={animationBreakpoints}
        >
          <div
            className={classNames(background_, squeezeContainer_, 'sectionSqueezeAnimationDiv')}
            ref={ref}
          />
        </SectionSqueezeAnimation>
      )}
      {!isSqueezeAnimation(animation) && hasBorderRadiusBottom && (
        <div
          className={classNames(
            background_,
            squeezeContainer_,
            squeezeContainerWithBorderRadius_,
            shadow && squeezeContainerHasShadow_
          )}
          ref={ref}
        />
      )}
      <div
        ref={contentRef}
        className={classNames(
          'SectionContent',
          hasSqueezeContentAnimation && background_,
          SPACING_COLLAPSE_TO_CLASS[verticalSpacingCollapse],
          contentClassName,
          shouldUseDarkTheme && 'ws-content-dark-theme',
          content_
        )}
      >
        <div className={classNames('SectionContainer', containerClassName)}>
          {React.Children.map(children, (child, index) => {
            const isFirstChild = index === 0;
            const isLastChild = index === React.Children.count(children) - 1;
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                sectionIndex,
                subsectionIndex: index,
                isFirstChild,
                isLastChild,
                shouldUseDarkTheme,
                colorScheme,
              });
            }
            return child;
          })}
        </div>
      </div>
      {afterContent}
      <div id={scrollDetectionId} className={scrollTrigger_} />
    </section>
  );

  if (animation === 'squeeze-content-do-not-use-or-you-will-be-haunted-by-spooky-ghosts') {
    return (
      <SectionSqueezeAnimation breakpoints={animationBreakpoints} ref={contentRef}>
        {sectionComponent}
      </SectionSqueezeAnimation>
    );
  }

  return sectionComponent;
});

const SPACING_COLLAPSE_TO_CLASS = {
  none: sectionContent_,
  all: sectionCollapse_,
  top: sectionCollapseTop_,
  bottom: sectionCollapseBottom_,
};

Section.propTypes = {
  background: PropTypes.string,
  wrapperBackground: PropTypes.string,
  colorScheme: PropTypes.shape({}),
  zIndex: PropTypes.number,
  className: PropTypes.string,
  forceFullWidth: PropTypes.bool,
  verticalSpacing: PropTypes.oneOf(['normal', 'tight']),
  verticalSpacingCollapse: PropTypes.oneOf(['none', 'top', 'bottom', 'all']),
  children: PropTypes.node.isRequired,
  sectionIndex: PropTypes.number,
  animation: PropTypes.oneOf([
    'squeeze',
    'squeeze-in-out',
    'squeeze-content-do-not-use-or-you-will-be-haunted-by-spooky-ghosts',
    'reveal',
  ]),
  shadow: PropTypes.bool,
  hasBorderRadiusBottom: PropTypes.bool,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  darkTheme: PropTypes.bool,
};

Section.displayName = 'Section';

export { Section };
