export const cookieBannerReducer = (oldState, state) => {
  const newState = { ...oldState, ...state };
  const shouldShowBanner = newState.isEligible === true && newState.hasPreference === false;
  const shouldLoadSegment =
    newState.isEligible === false ||
    (newState.isEligible === true && newState.hasPreference === true);

  return {
    ...newState,
    shouldShowBanner,
    shouldLoadSegment,
  };
};
