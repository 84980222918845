/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const ResilientGraph = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M1 0.649414C1.55228 0.649414 2 1.09713 2 1.64941L2 36.763C2 37.3153 1.55229 37.763 1 37.763C0.447717 37.763 1.60272e-06 37.3153 1.57857e-06 36.763L4.37114e-08 1.64941C1.95703e-08 1.09713 0.447715 0.649414 1 0.649414Z" fill={color} /><path d="M10 29L10.3562 22.5522L20.3288 24.3433L21.0411 18.6119L36 5" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /><path d="M26 5H36V15" stroke={color} strokeWidth={2} strokeLinecap="round" /><path fillRule="evenodd" clipRule="evenodd" d="M38.1133 37.7629C38.1133 38.3152 37.6656 38.7629 37.1133 38.7629L-0.000278473 38.7629V36.7629L37.1133 36.7629C37.6656 36.7629 38.1133 37.2107 38.1133 37.7629Z" fill={color} /></svg>;
};
ResilientGraph.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default ResilientGraph;