import React from 'react';
import { WsEmailValidation } from '@wealthsimple/email-validation-ts';
import memoize from 'lodash/memoize';
import createHash from 'create-hash';
import { DEFAULT_LOCALE_LOWERCASE } from 'src/utils/localization/constants';
import { cleanDollarValue, formatDollarAmount } from 'src/utils/formatters';
import { FormValidationErrorMessage } from './FormValidationErrorMessage.component';

// eslint-disable-next-line react/display-name
export const required = (fieldName, customMessage) => function (value) {
  return value ? null : (
    <FormValidationErrorMessage
      type="required"
      interpolations={{ fieldName }}
      customMessage={customMessage}
    />
  );
};

// eslint-disable-next-line react/display-name
export const minLength = min => function (value) {
  return value?.length >= min ? null : (
    <FormValidationErrorMessage type="minLength" interpolations={{ min }} />
  );
};

export const email = value =>
  (WsEmailValidation.isEmailValid(value) || value === '' ? null : (
    <FormValidationErrorMessage type="email" />
  ));

export const phoneNumber = (value = '') =>
  // ignore any characters that aren't numbers
  ((value.match(/\d/g) ?? []).length === 10 || value === '' ? null : (
    <FormValidationErrorMessage type="phoneNumber" />
  ));

export const passwordNotCompromised = memoize(async password => {
  const hash = createHash('sha1')
    .update(password, 'utf-8')
    .digest('hex');

  const searchPrefix = hash.substr(0, 5);
  const resultSuffix = hash.substr(5);

  const response = await fetch(`https://api.pwnedpasswords.com/range/${searchPrefix}`);
  const data = await response.text();
  const vulnHashSuffixes = data.split('\n').map(entry => entry.split(':')[0]);
  const passwordIsCompromised = vulnHashSuffixes.includes(resultSuffix.toUpperCase());

  return passwordIsCompromised ? (
    <FormValidationErrorMessage type="passwordNotCompromised" />
  ) : null;
});

// eslint-disable-next-line react/display-name
export const dollarAmount = (config = {}) => function (value = '', locale = DEFAULT_LOCALE_LOWERCASE) {
  const cleanValue = cleanDollarValue(value, { locale, ...config });
  const valueToNum = parseFloat(cleanValue);
  const validValue = formatDollarAmount(valueToNum, { locale, ...config });
  const isInvalid = value !== validValue;

  return isInvalid ? <FormValidationErrorMessage type="dollarAmount" /> : null;
};

export const composeValidators = (...validators) => (value, locale) =>
  validators.reduce((error, validator) => error || validator(value, locale), null);
