export const translations = {
  'en-ca': {
    'footer::subtitle':
      'Sign up for our weekly non-boring newsletter about money, markets, and more.\n\n[Meet TLDR](/learn/tldr-newsletter-signup)',
    'footer::button': 'Subscribe',
  },
  'fr-ca': {
    'footer::subtitle':
      'Abonnez-vous à notre amusante infolettre hebdomadaire sur l’argent, les marchés et plus encore. Nos excuses : TLDR n’est offerte qu’en anglais pour le moment.',
    'footer::button': 'Abonnez-vous',
  },
};
