/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Knowledge = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M0 0H21.2063C21.7586 0 22.2063 0.447715 22.2063 1C22.2063 1.55228 21.7586 2 21.2063 2H2V35.8764H30.2785C30.8308 35.8764 31.2785 36.3242 31.2785 36.8764C31.2785 37.4287 30.8308 37.8764 30.2785 37.8764H0V0Z" fill={color} /><circle cx={17.469} cy={16.4705} r={7.22296} stroke={color} strokeWidth={2} /><path fillRule="evenodd" clipRule="evenodd" d="M29.4098 30.3981L22.2147 23.203L23.6289 21.7888L30.824 28.9839C31.2145 29.3744 31.2145 30.0076 30.824 30.3981C30.4335 30.7886 29.8003 30.7886 29.4098 30.3981Z" fill={color} /><path fillRule="evenodd" clipRule="evenodd" d="M18 23L18 14L20 14L20 23L18 23Z" fill={color} /><path fillRule="evenodd" clipRule="evenodd" d="M14 23L14 16L16 16L16 23L14 23Z" fill={color} /></svg>;
};
Knowledge.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Knowledge;