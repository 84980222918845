import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { classNames } from 'src/utils/css';
import { GRID_CELL_DEFAULT_CONFIG } from './RefreshGrid.constants';
import { base_ } from './RefreshCell.module.scss';

// converts whole values to a config object, since a single int or string is considered a value for column width
const groomBreakpointConfig = config => {
  if (typeof config === 'number' || typeof config === 'string') return { width: config };
  if (!config) return {};
  return config;
};

const populateMediaQuery = (mediaConfig, breakpoint) => {
  const { width, height, order, left, top, middle } = mediaConfig;

  let bpClassNames = [];

  // tailwind classes
  if (width) bpClassNames.push(`col-span-${width}`);
  if (height) bpClassNames.push(`row-span-${height}`);
  if (order) bpClassNames.push(`order-${order}`);
  if (left) bpClassNames.push(`col-start-${left}`);
  if (top) bpClassNames.push(`row-start-${top}`);
  if (middle) {
    bpClassNames.push('inline-flex');
    bpClassNames.push('flex-col');
    bpClassNames.push('flex-wrap');
    bpClassNames.push('justify-center');
    bpClassNames.push('justify-self-stretch');
    bpClassNames.push('items-center');
  }

  // add tailwind breakpoint prefixes
  if (breakpoint) {
    bpClassNames = bpClassNames.map(className => ([breakpoint, className].join(':')));
  }

  return bpClassNames;
};

export const RefreshCell = forwardRef(function RefreshCell(
  { className, children, xs, sm, md, lg, xl, all, as: CellElement = 'div', ...rest },
  ref
) {
  const allWithDefaults = {
    ...GRID_CELL_DEFAULT_CONFIG,
    ...groomBreakpointConfig(all),
    ...groomBreakpointConfig(xs),
  };

  const cellClassNames = [
    populateMediaQuery(allWithDefaults, null),
    populateMediaQuery(groomBreakpointConfig(sm), 'sm'),
    populateMediaQuery(groomBreakpointConfig(md), 'md'),
    populateMediaQuery(groomBreakpointConfig(lg), 'lg'),
    populateMediaQuery(groomBreakpointConfig(xl), 'xl'),
  ].flat();

  return (
    <CellElement
      ref={ref}
      className={classNames(base_, cellClassNames, className)}
      {...rest}
    >
      {children}
    </CellElement>
  );
});

const breakpointShape = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  middle: PropTypes.bool,
};

RefreshCell.propTypes = {
  /** Your content for inside the cell. Can be any valid React node. */
  children: PropTypes.node,
  /**
   * Configuration to apply to all breakpoints.
   *
   * Numbers and strings can be used as a shorthand for the `width` (in columns) property of the config object.
   *
   * e.g., `6`, `'6'`, and `{ width: 6 }` are all equivalent.
   */
  all: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `xs` breakpoint and up. */
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `sm` breakpoint and up. */
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `md` breakpoint and up. */
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `lg` breakpoint and up. */
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** Configuration to apply on the `xl` breakpoint. */
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape(breakpointShape)]),
  /** The HTML element type to be returned */
  as: PropTypes.node,
  className: PropTypes.string,
};
