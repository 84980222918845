import { gsap } from 'gsap';

// Best to not touch these. Using a normalized time makes things more adaptive. 0.5 is
// considered the halfway position. If you want an animation to last for a quarter of the
// section's scroll duration, you would give the animation tween a duration of 0.25.
const START_POSITION = 0;
const END_POSITION = 1;

const TimelineDummy = config => {
  return {
    all: () => {
      // timeline manages multiple tweens
      const timeline = gsap.timeline(config);

      // ensures min duration is 1. You go over that's on you.
      const dummy = { dummyVal: 0 };
      timeline.fromTo(
        dummy,
        { dummyVal: START_POSITION },
        { duration: END_POSITION, dummyVal: END_POSITION },
        START_POSITION
      );

      return () => {
        if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
        if (timeline) timeline.kill();
      };
    },
  };
};

export default TimelineDummy;
