export const DEFAULT_LOGIN_CTA_LABEL_EN = 'Log in';
export const DEFAULT_LOGIN_CTA_LABEL_FR = 'Connexion';

export const translations = {
  'en-ca': {
    'header::nav::label': 'Main menu',
    'header::cta::menu_toggle': 'Menu',
    'header::cta::login': DEFAULT_LOGIN_CTA_LABEL_EN,
    'header::nav::back::label': 'Back',
    'header::cta::skip_to_main_content': 'Skip to main content',
    'header::nav::managed_investing': `Managed investing`,
    'header::nav::managed_investing::info::heading': `Managed investing`,
    'header::nav::managed_investing::info::subheading': `Let us build you a custom portfolio that's designed for long-term growth. Plus, get expert advice whenever you need it.`,
    'header::nav::managed_investing::category::portfolios': `Portfolios`,
    'header::nav::managed_investing::category::portfolios::classic': `Classic portfolio`,
    'header::nav::managed_investing::category::portfolios::socially_responsible': `Socially responsible portfolio`,
    'header::nav::managed_investing::category::portfolios::halal': `Halal portfolio`,
    'header::nav::managed_investing::category::alternatives_investing': `Alternatives investing`,
    'header::nav::managed_investing::category::alternatives_investing::private_credit': `Private credit`,
    'header::nav::managed_investing::category::alternatives_investing::private_equity': `Private equity`,
    'header::nav::self_directed_investing': `Self-directed investing`,
    'header::nav::self_directed_investing::info::heading': `Self-directed investing`,
    'header::nav::self_directed_investing::info::subheading': `Take full control of your investments with smarter stock trading. Buy and sell over {{DIY_TRADEABLE_SECURITIES_COUNT}} stocks and ETFs — commission-free.`,
    'header::nav::self_directed_investing::category::investing_products': `Investing products`,
    'header::nav::self_directed_investing::category::investing_products::stocks_and_etfs': `Stocks & ETFs`,
    'header::nav::self_directed_investing::category::investing_products::options_trading': `Options trading`,
    'header::nav::spend': 'Cash',
    'header::nav::crypto': `Crypto`,
    'header::nav::mortgages': 'Mortgages',
    'header::nav::tax': 'Tax filing',
    'header::nav::account_types': `Account types`,
    'header::nav::account_types::info::heading': `Account types`,
    'header::nav::account_types::info::subheading': `Whether you’re saving up for the long run or building your first emergency fund, we have accounts that will help make the most of your money.`,
    'header::nav::account_types::category::investing': `Investing`,
    'header::nav::account_types::category::investing::rrsp': `Registered Retirement Savings Plan (RRSP)`,
    'header::nav::account_types::category::investing::tfsa': `Tax-Free Savings Account (TFSA)`,
    'header::nav::account_types::category::investing::fhsa': `First Home Savings Account (FHSA)`,
    'header::nav::account_types::category::investing::all': `All accounts`,
    'header::nav::account_types::category::spending_and_saving': `Spending & Saving`,
    'header::nav::account_types::category::spending_and_saving::high_interest_cash_account': `High-interest cash account`,
    'header::nav::pricing': 'Pricing',
    'header::nav::support': 'Support',
    'header::nav::support::info::heading': `Support`,
    'header::nav::support::info::subheading': `Need a hand? We’re here to help. Get in touch with our team any time, or browse our articles to help answer your questions.`,
    'header::nav::support::category::help': `Help`,
    'header::nav::support::category::help::portfolio_review_service': `Portfolio review service`,
    'header::nav::support::category::help::transfer_account': `Transfer an account`,
    'header::nav::support::category::help::contact': `Contact us`,
    'header::nav::support::category::help::help_centre': `Help centre`,
    'header::nav::support::category::learn': `Learn`,
    'header::nav::support::category::learn::personal_finance_tips': `Personal finance how-tos`,
    'header::nav::support::category::learn::wealthsimple_magazine': `Wealthsimple Magazine`,
    'header::nav::support::category::learn::product_updates': `Product updates & new features`,
    'header::nav::support::category::tools': `Tools`,
    'header::nav::support::category::tools::tax_calculator': `Tax calculator`,
    'header::nav::support::category::tools::rrsp_calculator': `RRSP calculator`,
    'header::nav::support::category::tools::tfsa_calculator': `TFSA calculator`,
    'header::nav::support::category::tools::retirement_calculator': `Retirement calculator`,
    'header::nav::support::category::tools::fee_calculator': `Fee calculator`,
  },
  'fr-ca': {
    'header::nav::label': 'Main menu',
    'header::cta::menu_toggle': 'Menu',
    'header::cta::login': DEFAULT_LOGIN_CTA_LABEL_FR,
    'header::cta::skip_to_main_content': 'Skip to main content',
    'header::nav::managed_investing': `Placements gérés`,
    'header::nav::managed_investing::info::heading': `Placements gérés`,
    'header::nav::managed_investing::info::subheading': `Nous vous proposerons un portefeuille sur mesure, visant une croissance à long terme. De plus, obtenez les conseils de spécialistes lorsque vous en avez besoin.`,
    'header::nav::managed_investing::category::portfolios': `Portefeuilles`,
    'header::nav::managed_investing::category::portfolios::classic': `Portefeuille Classique`,
    'header::nav::managed_investing::category::portfolios::socially_responsible': `Portefeuille Socialement responsable`,
    'header::nav::managed_investing::category::portfolios::halal': `Portefeuille Halal`,
    'header::nav::managed_investing::category::alternatives_investing': `Placements alternatifs`,
    'header::nav::managed_investing::category::alternatives_investing::private_credit': `Crédit privé`,
    'header::nav::managed_investing::category::alternatives_investing::private_equity': `Capital-investissement`,
    'header::nav::self_directed_investing': `Placements autonomes`,
    'header::nav::self_directed_investing::info::heading': `Placements autonomes`,
    'header::nav::self_directed_investing::info::subheading': `Prenez le contrôle de vos investissements sur notre plateforme d'opérations boursières supérieures. Achetez et vendez plus de {{DIY_TRADEABLE_SECURITIES_COUNT}} actions et FNB, sans commission.`,
    'header::nav::self_directed_investing::category::investing_products': `Produits de placements`,
    'header::nav::self_directed_investing::category::investing_products::stocks_and_etfs': `Actions et fonds négociés en bourse`,
    'header::nav::self_directed_investing::category::investing_products::options_trading': `Options`,
    'header::nav::spend': 'Dépenses et Épargne',
    'header::nav::crypto': `Crypto`,
    'header::nav::tax': `Impôts`,
    'header::nav::account_types': `Nos comptes`,
    'header::nav::account_types::info::heading': `Nos comptes`,
    'header::nav::account_types::info::subheading': `Que vous épargniez en vue de réaliser un objectif à long terme ou de constituer votre fonds d'urgence, nos comptes vous aideront à tirer le maximum de votre argent.`,
    'header::nav::account_types::category::investing': `Comptes de placement`,
    'header::nav::account_types::category::investing::rrsp': `Régime enregistré d'épargne-retraite (REER)`,
    'header::nav::account_types::category::investing::tfsa': `Compte d'épargne libre d'impôt (CELI)`,
    'header::nav::account_types::category::investing::fhsa': `Achat d'une première propriété (CELIAPP)`,
    'header::nav::account_types::category::investing::all': `Tous nos comptes`,
    'header::nav::account_types::category::spending_and_saving': `Compte de dépense et d'épargne`,
    'header::nav::account_types::category::spending_and_saving::high_interest_cash_account': `Compte d'épargne à intérêt élevé`,
    'header::nav::pricing': 'Tarification',
    'header::nav::support': 'Soutien',
    'header::nav::support::info::heading': `Soutien`,
    'header::nav::support::info::subheading': `Besoin d'un coup de main? Nous sommes ici pour vous aider. Contactez notre équipe en tout temps ou parcourez nos articles pour trouver réponse à vos questions.`,
    'header::nav::support::category::help': `Aide`,
    'header::nav::support::category::help::portfolio_review_service': `Évaluation de portefeuille`,
    'header::nav::support::category::help::transfer_account': `Transfert de compte`,
    'header::nav::support::category::help::contact': `Nous joindre`,
    'header::nav::support::category::help::help_centre': `Centre d'aide`,
    'header::nav::support::category::learn': `Articles`,
    'header::nav::support::category::learn::personal_finance_tips': `Astuces financières`,
    'header::nav::support::category::learn::wealthsimple_magazine': `Magazine Wealthsimple`,
    'header::nav::support::category::learn::product_updates': `Nouveautés sur les produits`,
    'header::nav::support::category::tools': `Outils`,
    'header::nav::support::category::tools::tax_calculator': `Calculateur d'impôt`,
    'header::nav::support::category::tools::rrsp_calculator': `Calculateur REER`,
    'header::nav::support::category::tools::tfsa_calculator': `Calculateur CELI`,
    'header::nav::support::category::tools::retirement_calculator': `Calculateur de retraite`,
    'header::nav::support::category::tools::fee_calculator': `Calculateur de frais`,
  },
};
