import { toFieldPath } from '@stackbit/annotations';
import { CONTENTFUL_SECTIONS } from 'src/components/contentful/contentful.constants';
import { spacesToCamel } from '../strings';
import { getMediaBackgroundStyles, getPromotionalSectionBackgroundStyles } from './media-helpers';
import { cleanString } from './clean-string';

export const normalizeListProps = ({ pointsList = [] }) => {
  if (!pointsList || pointsList.length === 0) {
    return {};
  }

  return {
    list: pointsList.map(({ contentfulId, itemHeading, itemBody }) => ({
      heading: cleanString(itemHeading),
      details: itemBody?.itemBody,
      entryId: contentfulId,
    })),
  };
};

export const normalizeHeadingProps = ({
  sectionHeading,
  sectionBody,
  sectionEyebrow,
}) => {
  if (!sectionHeading && !sectionBody) {
    return {};
  }

  return {
    sectionHeading: {
      eyebrow: cleanString(sectionEyebrow),
      heading: cleanString(sectionHeading),
      subheading: sectionBody?.sectionBody,
    },
  };
};

export const normalizeExtraTextProps = ({ sectionDisclaimer }) => {
  if (!sectionDisclaimer) {
    return {};
  }

  return {
    sectionDisclaimer: sectionDisclaimer.sectionDisclaimer,
  };
};

export const normalizeNoteProps = ({ sectionNoteHeading, sectionNoteBody }) => {
  if (!sectionNoteHeading && !sectionNoteBody) {
    return {};
  }

  return {
    sectionNote: {
      heading: cleanString(sectionNoteHeading),
      body: sectionNoteBody?.sectionNoteBody,
    },
  };
};

// See props in <ContentfulCTA /> component
export const prepareCta = ({ fieldNamePrefix, ...props }) => ({
  labelFieldPath: toFieldPath(`${fieldNamePrefix}Label`),
  ...toFieldPath(`${fieldNamePrefix}Url`),
  ...props,
});

export const normalizeSingleCtaProps = ({ ctaLabel, ctaUrl }) => {
  if (!ctaLabel || !ctaUrl) {
    return {};
  }
  return {
    cta: prepareCta({
      fieldNamePrefix: 'cta',
      link: ctaUrl,
      label: cleanString(ctaLabel),
    }),
  };
};

export const normalizeMultiCtaProps = ({
  primaryCtaLabel,
  primaryCtaUrl,
  secondaryCtaLabel,
  secondaryCtaUrl,
}) => {
  return {
    ...primaryCtaUrl ? {
      primaryCta: prepareCta({
        fieldNamePrefix: 'primaryCta',
        link: primaryCtaUrl,
        label: cleanString(primaryCtaLabel),
      })
    } : {},
    ...secondaryCtaUrl ? {
      secondaryCta: prepareCta({
        fieldNamePrefix: 'secondaryCta',
        link: secondaryCtaUrl,
        label: cleanString(secondaryCtaLabel),
      })
    } : {},
  };
};

// We are very limited to a set of style properties, which is why we are mapping the alignment properties
// For more info on how the style field works see:
// https://visual-editor-reference.netlify.com/config/content-modeling/fields/style-field
export const normalizeStyleProps = ({ style }, componentConfig) => {
  if (!style) {
    return {};
  }

  const { mediaBackground, background } = style;

  return {
    style: {
      ...style,
      ...mediaBackground && componentConfig?.contentTypeId === CONTENTFUL_SECTIONS.MEDIA_WITH_DESCRIPTION ? {
        mediaBackground: getMediaBackgroundStyles(mediaBackground)
      } : {},
      ...background && componentConfig?.contentTypeId === CONTENTFUL_SECTIONS.PROMOTIONAL ? {
        background: getPromotionalSectionBackgroundStyles(background)
      } : {},
    }
  };
};

const ALLOWED_PROP_KEYS = [
  'accountDetails',
  'className',
  'columnEyebrows',
  'specialContentSelection',
  'table',
  'tableFootnote',
  'citationName',
  'featureCards',
  'cardLayout',
  'background',
  'locales',
  'mediaBackground',
  'mediaCaption',
  'tierCards',
];

export const normalizeProps = ({
  layout,
  asset,
  imageAlignment,
  mobileImageAlignment,
  ...props
} = {},
componentConfig = {}) => ({
  ...(layout ? { layout: spacesToCamel(layout) } : {}),
  ...(asset
    ? {
      asset,
      ...(imageAlignment ? { imageAlignment: spacesToCamel(imageAlignment) } : {}),
      ...(mobileImageAlignment ? { mobileImageAlignment: spacesToCamel(mobileImageAlignment) } : {}),
    }
    : {}),
  ...normalizeHeadingProps(props),
  ...normalizeExtraTextProps(props),
  ...normalizeListProps(props),
  ...normalizeSingleCtaProps(props),
  ...normalizeMultiCtaProps(props),
  ...normalizeNoteProps(props),
  ...normalizeStyleProps(props, componentConfig),
  ...ALLOWED_PROP_KEYS.reduce((acc, prop) => {
    return {
      ...acc,
      ...(props[prop] ? { [prop]: props[prop] } : {}),
    };
  }, {}),
});
