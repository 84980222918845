import React, { isValidElement, cloneElement } from 'react';
import { classNames } from 'src/utils/css';
import { CTALinkButton } from './CTALinkButton.component';

/*
 * Convenience function which can allow you to render a CTALinkButton when provided as a ReactElement
 *
 * @param {ReactElement|object} cta -  A ReactElement or an object
 *    containing props for the CTALinkButton
 *    (if providing an object, `children` of button may also be provided as `label` key)
 * @param {object} propOverrides - a props object to override existing props
 * @returns {ReactElement} A CTALinkButton with the provided props overridden
 */
const getCTALinkButton = (cta, propOverrides = {}) => {
  const { label, children } = propOverrides;
  const overrideLabel = label || children;
  if (isValidElement(cta)) {
    // If it's a CTALinkButton we know the API and can pass it everything
    if (cta.type.name === 'CTALinkButton') {
      return cloneElement(cta, {
        ...propOverrides,
        className: classNames(cta.props.className, propOverrides.className),
        label: undefined,
        ...(overrideLabel ? { children: overrideLabel } : {}),
      });
    }

    // Attempt to pass most important props to unknown custom CTA component,
    // on the assumption said component knows what it's doing
    return cloneElement(cta, {
      ...propOverrides,
      className: classNames(cta.props.className, propOverrides.className),
    });
  }

  const defaultLabel = cta.label || cta.children;
  const props = {
    ...cta,
    ...propOverrides,
    className: classNames(cta.className, propOverrides.className),
    label: undefined,
    children: overrideLabel || defaultLabel,
  };
  return <CTALinkButton {...props} />;
};

export { getCTALinkButton };
