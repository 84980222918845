import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { getFramework } from 'lib/get-framework';
import { isEquivalentLocale } from 'src/utils/localization';
import { DEFAULT_META_IMG_URL } from 'src/utils/seo';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';

/**
 * @deprecated - Only used for Gatsby
 */
export const useOgImageWithFallback = customImageUrl => {
  const { isGatsby } = getFramework();
  if (!isGatsby) return '';

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { locale } = useTranslationContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const defaultOgImages = useStaticQuery(graphql`
    query DefaultOgImageQuery {
      images: allContentfulMedia(filter: { title: { eq: "wsMedia_wsOpenGraphDefault" } }) {
        nodes {
          node_locale
          image {
            gatsbyImageData(layout: FIXED, width: 1200, quality: 90, formats: [JPG])
          }
        }
      }
    }
  `);

  if (customImageUrl && customImageUrl !== DEFAULT_META_IMG_URL) return customImageUrl;

  return (
    getSrc(
      defaultOgImages?.images?.nodes?.filter(node =>
        isEquivalentLocale(node.node_locale, locale)
      )[0].image.gatsbyImageData
    ) ?? DEFAULT_META_IMG_URL
  );
};
