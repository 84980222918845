/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const ArrowDown = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M8 0C8.55228 0 9 0.447715 9 1V12.5858L14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289C0.683417 6.90237 1.31658 6.90237 1.70711 7.29289L7 12.5858V1C7 0.447715 7.44772 0 8 0Z" fill={color} /></svg>;
};
ArrowDown.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default ArrowDown;