import { useEffect, useRef } from 'react';

const useDisableScroll = trigger => {
  const scrollPosRef = useRef({ x: 0, y: 0 });

  const onScrollDisabledHandler = () =>
    window.scrollTo(scrollPosRef.current?.x, scrollPosRef.current?.y);

  const disableScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    scrollPosRef.current = { x: scrollLeft, y: scrollTop };
    window.addEventListener('scroll', onScrollDisabledHandler);
    document.body.classList.add('scroll-disabled');
  };

  const enableScroll = () => {
    document.body.classList.remove('scroll-disabled');
    window.removeEventListener('scroll', onScrollDisabledHandler);
  };

  useEffect(() => {
    if (trigger) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [trigger]);
};

export { useDisableScroll };
