'use client';

import { useLocation } from '@gatsbyjs/reach-router';
import { usePathname as useNextPathname } from 'next/navigation';
import { getFramework } from 'lib/get-framework';

/**
 * usePathname is intended to be a temporary shim for NextJS's usePathname until
 * the migration away from Gatsby is complete. Once it is, we can replace all
 * calls to this usePathname with calls directly to usePathname from next/navigation.
 *
 * @returns String
 */

export const usePathname = () => {
  const { isGatsby } = getFramework();
  // The hook will always be called in the same order in the context of any
  // given framework environment, so we don't need to worry about rules-of-hooks
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = isGatsby ? useLocation().pathname : useNextPathname();
  return pathname;
};
