import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { Markdown } from 'src/tapestry/core/markdown';
import { classNames } from 'src/utils/css';
import { Logger } from 'src/utils/logging';
import { useClientRect } from 'src/hooks/useClientRect';
import { FluidText } from 'src/components/common/FluidText';
import { TOOLTIP_ALIGNMENT } from './tooltip.constants';
import { checkCollisions, findOverflowNotVisibleParent } from './tooltip.helpers';
import {
  wrapper_,
  noUnderline_,
  label_,
  button_,
  bubble_,
  alignLeft_,
  alignCenter_,
  alignRight_,
  alignTop_,
  alignBottom_,
  alignTipToContentCenter_,
  tooltipTextBlock_,
} from './tooltip.module.scss';

const ALIGNMENT_CLASS_MAPPING = {
  [TOOLTIP_ALIGNMENT.LEFT]: alignLeft_,
  [TOOLTIP_ALIGNMENT.RIGHT]: alignRight_,
  [TOOLTIP_ALIGNMENT.TOP]: alignTop_,
  [TOOLTIP_ALIGNMENT.BOTTOM]: alignBottom_,
  [TOOLTIP_ALIGNMENT.CENTER]: alignCenter_,
};

const toolTipTextRenderer = () => ({
  paragraph: ({ children }) => (
    <FluidText
      type="div"
      className={tooltipTextBlock_}
      min="ws-text-sm"
      max="ws-text-lg"
    >
      {children}
    </FluidText>
  ),
});

export const Tooltip = ({
  children,
  className = '',
  ariaLabel = '',
  description = '',
  preferredHorizontalAlignment = TOOLTIP_ALIGNMENT.LEFT,
  preferredVerticalAlignment = TOOLTIP_ALIGNMENT.TOP,
  forceVerticalAlignment,
  alignTipToContentCenter = false,
  removeUnderline = false,
  isDisabled = false,
  collisionDetectAgainstClippedAncestor = false,
  ...rest
}) => {
  const [tooltipRef, tooltipRectangle] = useClientRect();
  const [wrapperRef, wrapperRectangle, wrapperNode] = useClientRect();
  const clippedAncestorRef = useRef();
  const [actualHorizontalAlignment, setActualHorizontalAlignment] = useState(
    preferredHorizontalAlignment
  );
  const [actualVerticalAlignment, setActualVerticalAlignment] = useState(
    preferredVerticalAlignment
  );

  const verifyAlignment = () => {
    if (collisionDetectAgainstClippedAncestor && wrapperNode?.parentElement) {
      clippedAncestorRef.current = findOverflowNotVisibleParent(wrapperNode.parentElement);
    }

    const clippedAncestorRectangle = clippedAncestorRef.current?.getBoundingClientRect();

    // do we have real rects
    if (wrapperRectangle?.x && tooltipRectangle?.x) {
      const { finalHorizontalAlignment, finalVerticalAlignment } = checkCollisions(
        preferredHorizontalAlignment,
        preferredVerticalAlignment,
        wrapperRectangle,
        tooltipRectangle,
        clippedAncestorRectangle
      );

      setActualHorizontalAlignment(finalHorizontalAlignment);
      setActualVerticalAlignment(finalVerticalAlignment);
    }
  };

  useLayoutEffect(() => {
    verifyAlignment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRectangle, tooltipRectangle, preferredHorizontalAlignment, preferredVerticalAlignment]);

  // determine how aria-label is getting populated
  const labelText = !ariaLabel && isString(children) ? children : ariaLabel;
  if (labelText === '') {
    Logger.warn(
      '`aria-label` hasn\'t been assigned a proper string. Make sure children is a string, or else manually assign a value with the "ariaLabel" prop.'
    );
  }
  const isRerender = true;

  return (
    <div
      className={classNames(wrapper_, removeUnderline && noUnderline_, className)}
      ref={wrapperRef}
      {...rest}
    >
      <button
        type="button"
        className={button_}
        // prevent screenreaders/keyboard navigation from tabbing to this input if it's disabled.
        tabIndex={isDisabled ? -1 : 0}
        disabled={isDisabled}
        aria-label={labelText}
      >
        <span className={label_}>{children}</span>
      </button>
      <div
        ref={tooltipRef}
        className={classNames(
          bubble_,
          ALIGNMENT_CLASS_MAPPING[actualHorizontalAlignment],
          ALIGNMENT_CLASS_MAPPING[forceVerticalAlignment ?? actualVerticalAlignment],
          alignTipToContentCenter && alignTipToContentCenter_
        )}
        role="status"
        data-rerendered={isRerender ? 1 : 0}
      >
        {!isDisabled && <Markdown renderers={toolTipTextRenderer()} source={description} />}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  ariaLabel: PropTypes.string,
  description: PropTypes.string,
  preferredHorizontalAlignment: PropTypes.oneOf([TOOLTIP_ALIGNMENT.LEFT, TOOLTIP_ALIGNMENT.CENTER, TOOLTIP_ALIGNMENT.RIGHT]),
  preferredVerticalAlignment: PropTypes.oneOf([TOOLTIP_ALIGNMENT.TOP, TOOLTIP_ALIGNMENT.BOTTOM]),
  forceVerticalAlignment: PropTypes.oneOf([TOOLTIP_ALIGNMENT.TOP, TOOLTIP_ALIGNMENT.BOTTOM]),
  removeUnderline: PropTypes.bool,
  isDisabled: PropTypes.bool,
  collisionDetectAgainstClippedAncestor: PropTypes.bool,
  alignTipToContentCenter: PropTypes.bool,
};
