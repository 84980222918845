import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { ArrowRight } from 'src/components/common/Icons';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import {
  wrapper_,
  innerContent_,
  innerContentTransparent_,
  backgroundLeft_,
  backgroundRight_,
  backgroundMiddle_,
  arrowExtender_,
  arrowExtenderTransparentBackground_,
  arrow_,
  arrowTransparent_,
  background_,
  leftToRight_,
  rightToLeft_,
  active_,
} from './ArrowLinkButton.module.scss';

const DEFAULT_ANIMATION_DIRECTION = 'leftToRight';

export const ArrowLinkButton = ({
  href,
  className,
  animationDirection,
  isActive,
  transparentBackground,
}) => {
  const uuid = useId();
  const backgroundClass = !transparentBackground && background_;
  const arrowClass = transparentBackground ? arrowTransparent_ : arrow_;
  const animationDirectionClass =
    animationDirection === DEFAULT_ANIMATION_DIRECTION ? leftToRight_ : rightToLeft_;

  return (
    <SmartLink className={classNames(wrapper_, className)} href={href} {...toXPathData('ArrowLinkButton_href')}>
      <div
        className={classNames(
          innerContent_,
          transparentBackground && innerContentTransparent_,
          isActive && active_,
          animationDirectionClass,
          arrowClass
        )}
      >
        <div className={classNames(backgroundLeft_, backgroundClass)} />
        <div className={classNames(backgroundRight_, backgroundClass)} />
        <div className={classNames(backgroundMiddle_, backgroundClass)} />
        <div
          className={classNames(
            arrowExtender_,
            transparentBackground && arrowExtenderTransparentBackground_
          )}
        />
        <ArrowRight id={uuid} />
      </div>
    </SmartLink>
  );
};

ArrowLinkButton.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  animationDirection: PropTypes.oneOf([DEFAULT_ANIMATION_DIRECTION, 'rightToLeft']),
  // this button has been exclusively used inside of Card components. We may want to add an "auto" property at some point, and let wrapper:hover mimic active_.
  isActive: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};

ArrowLinkButton.defaults = {
  animationDirection: DEFAULT_ANIMATION_DIRECTION,
  isActive: 'auto',
};
