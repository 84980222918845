'use client';

import React, { useId, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TimelineDummy from './global-animations/timeline.dummy';

gsap.registerPlugin(ScrollTrigger);

const delayedGlobalRefresh = () => {
  ScrollTrigger.refresh();
};

export const AnimatedScrollBlock = ({
  children,
  className,
  timeline = TimelineDummy,
  startPosition = 'top bottom',
  endPosition = 'bottom top',
  markers,
  scrub = true,
  id,
  onEnter = () => null,
  onLeave = () => null,
  onEnterBack = () => null,
  onLeaveBack = () => null,
  onToggle = () => null,
  timelineDependencies,
  toggleClass,
  manualPercentRef,
  style,
  once,
  trigger,
  manualScrubScale = 0.0325,
}) => {
  const bgElementRef = useRef();
  const nanoIdRef = useRef(useId());

  const initialValue = manualPercentRef?.current ?? 0;
  const naturalPercentRef = useRef({ current: initialValue, last: initialValue });
  const _manualPercentRef = useRef({ current: initialValue, last: initialValue });

  useLayoutEffect(() => {
    let triggerProps;
    if (typeof trigger === 'object' && trigger.start && trigger.end) {
      triggerProps = { startTrigger: trigger.start, endTrigger: trigger.end };
    } else {
      triggerProps = { trigger: trigger ?? bgElementRef.current };
    }

    const config = {
      scrollTrigger: {
        id: id || nanoIdRef.current,
        markers,
        ...triggerProps,
        start: startPosition,
        end: endPosition,
        scrub,
        toggleClass,
        onToggle,
        onEnter,
        onLeave,
        onEnterBack,
        onLeaveBack,
        once,
      },
    };

    // Using a useRef prop to pass parent scroll updates. Bound
    // variables are expensive to render per frame, so parent must
    // handle looking for updates to ref.current.

    if (manualPercentRef) {
      config.scrollTrigger.onUpdate = e => {
        naturalPercentRef.current.last = naturalPercentRef.current.current;
        naturalPercentRef.current.current = e.progress;
      };

      const animate = () => {
        if (manualPercentRef) {
          // accelleration is powered by measuring the distance between natural scroll-percent and
          // the manual-eased-percent - slows down faster the close the number gets to zero.
          const delta =
            (naturalPercentRef.current.current - _manualPercentRef.current.current) *
            manualScrubScale;

          _manualPercentRef.current.current += delta;

          // return the lagged percent back to parent
          manualPercentRef.current = _manualPercentRef.current.current;
        }
        requestAnimationFrame(animate);
      };

      animate();
    }

    // All the timeline/scrolltrigger refresh and destroy stuff
    // should happen in timeline animation file.
    ScrollTrigger.matchMedia(timeline(config, timelineDependencies));

    gsap.delayedCall(1, delayedGlobalRefresh);

    return () => {
      if (ScrollTrigger.getById(nanoIdRef.current)?.kill) {
        ScrollTrigger.getById(nanoIdRef.current).kill(true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline]);
  return (
    <div ref={bgElementRef} className={className} style={style}>
      {children}
    </div>
  );
};

AnimatedScrollBlock.propTypes = {
  timeline: PropTypes.func,
  startPosition: PropTypes.string,
  endPosition: PropTypes.string,
  markers: PropTypes.bool,
  scrub: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onEnterBack: PropTypes.func,
  onLeaveBack: PropTypes.func,
  timelineDependencies: PropTypes.shape({}),
  manualPercentRef: PropTypes.shape({}),
  onToggle: PropTypes.func,
  once: PropTypes.bool,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      end: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ]),
  manualScrubScale: PropTypes.number,
};

AnimatedScrollBlock.displayName = 'AnimatedScrollBlock';
