import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { usePathname } from 'src/hooks/usePathname';
import { classNames } from 'src/utils/css';
import { pathnameHasLocale, replaceLocaleInPathname } from 'src/utils/urls';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { EVENT_NAMES } from 'src/services/analytics-service';
import { link_, primaryLink_, secondaryLink_ } from './NavLink.module.scss';

const NavLink = ({ children, secondary = false, href, className, parentLabel, ...props }) => {
  const pathname = usePathname();
  const { locale } = useTranslationContext();
  const isCurrent = pathname === (pathnameHasLocale(href) ? href : replaceLocaleInPathname(href, locale));

  return (
    <SmartLink
      href={href}
      className={classNames(
        link_,
        !secondary ? `ws-text-lg-medium ${primaryLink_}` : `ws-text-xl ${secondaryLink_}`,
        className
      )}
      aria-current={isCurrent ? 'page' : null}
      eventData={{ name: EVENT_NAMES.NAV_CTA_CLICKED, colname: parentLabel }}
      {...props}
    >
      <span>{children}</span>
    </SmartLink>
  );
};

export { NavLink };
