/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Plus = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M13.0026 1.33325V24.6666M1.33594 12.9999H24.6693" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></svg>;
};
Plus.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Plus;