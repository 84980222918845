const GATSBY = 'gatsby';
const NEXTJS = 'nextjs';

const getFramework = () => {
  const framework = process.env.GATSBY_FRAMEWORK ?? process.env.NEXT_PUBLIC_FRAMEWORK ?? 'unknown';

  return ({
    framework,
    isGatsby: framework === GATSBY,
  });
};

module.exports = {
  GATSBY,
  NEXTJS,
  getFramework,
};
