import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { Image } from '../Image';
import wordmark from './media/ws-wordmark-refresh.svg';
import { translations } from './logo.translations';

export const LogoImgDefault = () => {
  const alt = useTranslation('ws-logo::home');
  return <Image width="172" height="26" alt={alt} src={wordmark} />;
};

const Logo = ({ className, renderCustomLogo }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  return renderCustomLogo ? (
    <span className={classNames(className)}>{renderCustomLogo()}</span>
  ) : (
    <SmartLink className={classNames(className)} href="/">
      <LogoImgDefault />
    </SmartLink>
  );
};

export { Logo };
