import React from 'react';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { CTAButton } from '../CTAButton';
import { MessageCircle } from '../Icons';
import { toggleChatbot } from './Chatbot.component';
import { translations } from './StartAChatButton.translations';

export const StartAChatButton = ({ size }) => {
  const componentContext = useComponentContext();
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const clickHandler = () => {
    trackAnalyticsEvent(EVENT_NAMES.CHATBOT_START_CHAT_CTA_CLICKED, {}, componentContext);
    toggleChatbot();
  };

  return (
    <CTAButton
      variant="secondary"
      size={size}
      iconPosition="right"
      icon={<MessageCircle title={useTranslation('start-a-chat-button::icon')} />}
      onClick={clickHandler}
    >
      {useTranslation('start-a-chat-button::label')}
    </CTAButton>
  );
};
