import { generateFluidClampString } from 'src/utils/css';

export const GRID_DEFAULT_COLUMNS = 12;

export const GRID_DEFAULT_CONFIG = {
  columns: GRID_DEFAULT_COLUMNS,
  gap: generateFluidClampString(32, 48, 320, 1440),
  columnGap: undefined,
  rowGap: undefined,
  rows: undefined,
};

export const GRID_CELL_DEFAULT_CONFIG = {
  width: GRID_DEFAULT_COLUMNS,
  height: 1,
  order: undefined,
  top: undefined,
  left: undefined,
  middle: undefined,
};
