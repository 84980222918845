'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { getFramework } from 'lib/get-framework';
import getFrameworkEnvVar from 'lib/get-framework-env-var';
import { classNames } from 'src/utils/css';
import { VALID_LOCALES, getDelocalizedPath, getLanguageFromLocale } from 'src/utils/localization';
import { generateBreadCrumbs, getConsolidatedMetadata } from 'src/utils/seo';
import { getCanonicalUrl } from 'src/utils/urls';
import { useHeaderContext } from 'src/components/layout';
import { usePageAttributesContext } from 'src/components/contexts/PageAttributesContext';
import {
  useStringWithGlobals,
  useTranslationContext,
} from 'src/components/contexts/TranslationContext';
import { useOgImageWithFallback } from 'src/hooks/useOgImageWithFallback';
import { usePathname } from 'src/hooks/usePathname';

import serif from 'src/styles/base/fonts/tiempos-headline-regular.woff2';
import futuraBook from 'src/styles/base/fonts/futura-pt-book-latin.woff2';
import futuraSans from 'src/styles/base/fonts/futura-pt-medium-latin.woff2';
import favicon16 from './media/favicon-16.png';
import favicon32 from './media/favicon-32.png';
import favicon196 from './media/favicon-196.png';

/**
 * This is temporary. Eventually (probably) the entire DocumentHeadContent
 * component will be deprecated as Next.js provides different APIs for all of
 * this metadata; APIs which do not require the use of Helmet.
 */
const GatsbyOnlyThings = ({ children, title }) => (
  <Helmet title={title}>
    {/* Resource Hints */}
    <link rel="preconnect" href="https://images.ctfassets.net" />

    {/* Preload fonts */}
    <link rel="preload" as="font" href={serif} type="font/woff2" crossOrigin="anonymous" />
    <link rel="preload" as="font" href={futuraSans} type="font/woff2" crossOrigin="anonymous" />
    <link rel="preload" as="font" href={futuraBook} type="font/woff2" crossOrigin="anonymous" />

    {/* Theme */}
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />

    {/* Links and metadata for favicon */}
    <link rel="apple-touch-icon" href={favicon196} />
    <link rel="icon" type="image/png" sizes="196x196" href={favicon196} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />

    {/*
      Safari turns phone numbers into links, which causes a server/client
      mismatch that causes React to throw an error and fall back to client
      rendering. There are places where we want phone numbers to be tappable
      (our Contact page - where we link them manually), but in other places
      it's not as critical. Learn articles, documents, etc.
    */}
    <meta name="format-detection" content="telephone=no" />

    {children}
  </Helmet>
);

export const DocumentHeadContent = ({
  metadata = {},
  legacyMetadata,
  bodyClassName,
  dataProps = {},
  breadcrumbs = [],
  children,
}) => {
  const { isGatsby } = getFramework();
  const BASE_URL = getFrameworkEnvVar('BASE_URL');
  const seoMeta = getConsolidatedMetadata(metadata, legacyMetadata);
  const { locale } = useTranslationContext();
  const { supportedLocales } = usePageAttributesContext();
  const { isMobileNavOpen } = useHeaderContext();
  const pathname = usePathname();
  const canonicalUrl = seoMeta.ogUrl || getCanonicalUrl(pathname);
  const validSupportedLocales = supportedLocales.filter(supportedLocale =>
    VALID_LOCALES.includes(supportedLocale)
  );
  const titleWithGlobals = useStringWithGlobals(seoMeta.pageTitle);
  const descriptionWithGlobals = useStringWithGlobals(seoMeta.pageDescription);
  const ogTitleWithGlobals = useStringWithGlobals(seoMeta.ogTitle);
  const ogDescriptionWithGlobals = useStringWithGlobals(seoMeta.ogDescription);
  const ogImageWithFallback = useOgImageWithFallback(seoMeta.ogImage);

  return (
    <>
      {isGatsby && (
        <GatsbyOnlyThings title={titleWithGlobals}>
          <html lang={locale.substr(0, 2)} />
          <meta property="twitter:site" content="@wealthsimple" />
          <meta property="twitter:creator" content="@wealthsimple" />

          {/* Metadata for SEO */}
          {seoMeta.noIndex && <meta content="noindex" name="robots" />}
          <script type="application/ld+json">{generateBreadCrumbs(breadcrumbs)}</script>
          <meta property="description" name="description" content={descriptionWithGlobals} />
          <meta property="fb:app_id" content={seoMeta.fbAppId} />
          <meta
            property="og:description"
            name="og:description"
            content={ogDescriptionWithGlobals}
          />
          <meta property="og:type" name="og:type" content={seoMeta.ogType} />
          <meta property="og:url" name="og:url" content={canonicalUrl} />
          <meta property="og:image" name="og:image" content={ogImageWithFallback} />
          <meta property="og:title" name="og:title" content={ogTitleWithGlobals} />
          {seoMeta.twitterTitle && (
            <meta property="twitter:title" content={seoMeta.twitterTitle} />
          )}
          {seoMeta.twitterDescription && (
            <meta property="twitter:description" content={seoMeta.twitterDescription} />
          )}
          <meta property="twitter:card" content={seoMeta.twitterCard} name="twitter:card" />
          <link rel="canonical" href={canonicalUrl} />

          {/* hreflang definitions */}
          {/* based on supported locales */}
          {locale &&
            validSupportedLocales.length > 1 &&
            validSupportedLocales.map(supportedLocale => (
              <link
                key={supportedLocale}
                rel="alternate"
                hrefLang={supportedLocale.toLowerCase()}
                href={`${BASE_URL}/${supportedLocale.toLowerCase()}${getDelocalizedPath(pathname)}`}
              />
            ))}
          {/* generic language fallbacks - would need to update logic if multiple en or fr locales were added */}
          {validSupportedLocales.length > 1 &&
            validSupportedLocales.map(supportedLocale => {
              const language = getLanguageFromLocale(supportedLocale);
              return (
                <link
                  key={language}
                  rel="alternate"
                  hrefLang={language}
                  href={`${BASE_URL}/${supportedLocale.toLowerCase()}${getDelocalizedPath(pathname)}`}
                />
              );
            })}
          {/* if >1 locale and en-CA is supported, show it as default */}
          {validSupportedLocales.length > 1 && validSupportedLocales.includes('en-CA') && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`${BASE_URL}/en-ca${getDelocalizedPath(pathname)}`}
          />
          )}
        </GatsbyOnlyThings>
      )}
      <Helmet>
        <body
          className={classNames(bodyClassName)}
          {...(isMobileNavOpen ? { 'data-is-mobile-nav-open': true } : {})}
          {...process.env.GATSBY_CMS_PREVIEW === '1' ? { 'data-is-netlify-create': true } : {}}
          {...dataProps}
        />
        {children}
      </Helmet>
    </>
  );
};

DocumentHeadContent.propTypes = {
  metadata: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ pageDescription: PropTypes.string }),
    ]),
    ogDescription: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ ogDescription: PropTypes.string }),
    ]),
    ogImage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ ogImage: PropTypes.string }),
    ]),
    ogTitle: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ twitterDescription: PropTypes.string }),
    ]),
    ogType: PropTypes.string,
    fbAppId: PropTypes.string,
    noIndex: PropTypes.bool,
    ogUrl: PropTypes.string,
  }),
  legacyMetadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  bodyClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  breadcrumbs: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
  dataProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
