'use client';

import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

const HeaderContext = createContext();
HeaderContext.displayName = 'HeaderContext';

const HeaderContextProvider = ({ children }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(true);
  const [hasScrolledPastFirstSection, setHasScrolledPastFirstSection] = useState(false);

  useEffect(() => {
    const firstSectionObserver = new IntersectionObserver(entries => {
      const rect = entries[0].boundingClientRect;
      setHasScrolledPastFirstSection(rect.y + rect.height < 0);
    });

    const firstSection =
      document.querySelector('#section-0') ||
      document.querySelectorAll('section')?.[0] ||
      document.querySelector('main')?.childNodes[0];

    if (firstSection) {
      firstSectionObserver.observe(firstSection);
    }

    return () => {
      firstSectionObserver.disconnect();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isMobileNavOpen,
      setIsMobileNavOpen,
      isDesktopNavOpen,
      setIsDesktopNavOpen,
      hasScrolledPastFirstSection,
      setHasScrolledPastFirstSection,
    }),
    [isDesktopNavOpen, isMobileNavOpen, hasScrolledPastFirstSection]
  );

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
};

const useHeaderContext = () => useContext(HeaderContext);

export { HeaderContextProvider, useHeaderContext };
