import { gsap } from 'gsap';

const FPS = 60;
const SPEED_SCALAR = 10;
const PARTICLE_COUNT = 20;
const GRAVITY = 0.1 * SPEED_SCALAR;
const PARTICLE_DEFAULT_SIZE = 100;

export const ParticleEmitter = ({
  imgUrl,
  pageX,
  pageY,
  count = PARTICLE_COUNT,
  maxScale = 10,
}) => {
  let emojiIsPlaying = false;
  let emitterTargetEl;
  let activeEmitter;

  const update = () => {
    if (emojiIsPlaying) {
      let deadParticles = 0;
      activeEmitter = activeEmitter.map(particle => {
        if (particle.lifespan >= 0) {
          particle.location.x += particle.velocity.x - particle.accelleration.x; //  + particle.accelleration.x;
          particle.location.y += particle.velocity.y - particle.accelleration.y;
          particle.accelleration.y -= GRAVITY;
          particle.rotation += particle.rotationSpeed;
          particle.scale += particle.scaleSpeed;
          particle.lifespan -= 1;
          gsap.set(particle.element, {
            x: particle.location.x,
            y: particle.location.y,
            rotate: particle.rotation,
            scale: Math.min(particle.scale, maxScale),
          });
        } else {
          deadParticles += 1;
        }
        return particle;
      });

      // destroy or go to next frame
      if (deadParticles >= activeEmitter.length) {
        destroyEmitter();
      } else {
        window.requestAnimationFrame(update);
      }
    }
  };

  const destroyEmitter = () => {
    emojiIsPlaying = false;
    document.body.removeChild(emitterTargetEl);
  };

  const createParticles = emitterEl => {
    const particlesTemp = [];
    for (let i = 0; i < count; i++) {
      const particleImage = document.createElement('img');
      particleImage.src = imgUrl;

      particleImage.width = PARTICLE_DEFAULT_SIZE;
      particleImage.style.maxWidth = '100%';
      particleImage.style.aspectRatio = '1/1';
      particleImage.style.display = 'block';
      particleImage.height = PARTICLE_DEFAULT_SIZE;
      particleImage.style.position = 'absolute';
      particleImage.style.width = `${PARTICLE_DEFAULT_SIZE}px`;
      particleImage.style.height = `${PARTICLE_DEFAULT_SIZE}px`;

      particlesTemp.push({
        element: particleImage,
        alpha: 0,
        scale: Math.random() * 0.3 + 0.1,
        scaleSpeed: Math.random() * 0.01 * SPEED_SCALAR,
        rotation: Math.random() * Math.PI * 4,
        rotationSpeed: (Math.random() * 3 - 1.5) * SPEED_SCALAR,
        accelleration: {
          x: 1 * SPEED_SCALAR * (Math.random() * 1.5),
          y: 1 * SPEED_SCALAR * (Math.random() * 1.5),
        },
        velocity: {
          x: (Math.random() * 4 - 1.5) * SPEED_SCALAR,
          y: Math.random() * -4.2 * SPEED_SCALAR,
        },
        location: {
          x: 0,
          y: 0,
        },
        lifespan: FPS * SPEED_SCALAR * 100,
      });

      emitterEl.appendChild(particleImage);
    }
    return particlesTemp;
  };

  const startAnimationLoop = () => {
    window.requestAnimationFrame(update);
  };

  const createParticleEmitter = (x, y) => {
    if (emojiIsPlaying) return;

    const cursorX = x;
    const cursorY = y;

    emitterTargetEl = document.createElement('div');
    emitterTargetEl.style.zIndex = '99';
    emitterTargetEl.style.position = 'fixed';
    emitterTargetEl.style.width = `${PARTICLE_DEFAULT_SIZE}px`;
    emitterTargetEl.style.left = `${cursorX}px`;
    emitterTargetEl.style.top = `${cursorY}px`;

    activeEmitter = createParticles(emitterTargetEl);
    document.body.appendChild(emitterTargetEl);
    emojiIsPlaying = true;
  };

  const particleImage = document.createElement('img');
  particleImage.onload = () => {
    createParticleEmitter(pageX, pageY);
    startAnimationLoop();
  };
  particleImage.src = imgUrl;
};
