import { useEffect, useRef } from 'react';
import { getAllFocusableElements } from 'src/utils/focusable';

// Inspired by https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
const useTrapFocus = ({ element, shouldTrap, initialFocusElement }) => {
  const lastExternalActiveElement = useRef();

  useEffect(() => {
    const focusableElements = getAllFocusableElements(element);
    const firstFocusableElement = focusableElements?.[0];
    const lastFocusableElement = focusableElements?.[focusableElements.length - 1];

    // *** Keep focus within element *** //
    const handleTabPress = e => {
      if (e.key !== 'Tab') {
        return;
      }

      if (e.shiftKey && document.activeElement === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement.focus();
      }

      if (!e.shiftKey && document.activeElement === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    };

    // *** Add or remove handler event *** //
    if (shouldTrap) {
      lastExternalActiveElement.current = document.activeElement;
      document.addEventListener('keydown', handleTabPress);
    } else {
      document.removeEventListener('keydown', handleTabPress);
    }

    // *** Choose which element to focus on initially *** //
    // Focus on initialFocusElement
    if (shouldTrap && initialFocusElement) {
      initialFocusElement.focus();
    }
    // Focus on first element when triggered if nothing is passed for initialFocusElement
    if (shouldTrap && !initialFocusElement) {
      firstFocusableElement.focus();
    }

    // When the trap is released, focus on whichever element was focused on before "shouldTrap" was set to true
    // This should almost always be whichever element triggered "shouldTrap" anyway
    if (!shouldTrap && lastExternalActiveElement.current) {
      // iOS doesn't recognize the button touch focus events, so ignore if the lastExternalActiveElement is the initial gatsby-focus-wrapper.
      if (lastExternalActiveElement.current.id !== 'gatsby-focus-wrapper') {
        lastExternalActiveElement.current.focus();
      }
    }

    return () => {
      document.removeEventListener('keydown', handleTabPress);
    };
  }, [shouldTrap]);
};

export { useTrapFocus };
