import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { Tooltip } from 'src/tapestry/core/tooltip';
import { FluidText } from '../FluidText';
import { requiredLabel_, bottomMargin_, message_, tooltip_ } from './FormFieldLabel.module.scss';
import { Info } from '../Icons';

const TEXT_DEFAULT = 'default';
const TEXT_INLINE = 'inline';

const TEXT_CONFIGS = {
  default: { min: 'ws-text-md-medium', max: 'ws-text-lg-medium' },
  // Inline fields depend on this for the input focus transition
  inline: { min: 'ws-display-3xs-sans', max: 'ws-display-3xs-sans' },
};

export const FormFieldLabel = ({
  htmlFor,
  label,
  labelId,
  message,
  messageId,
  showLabel = true,
  requiredLabel,
  showRequiredLabel,
  isInline = false,
  tooltipDescription,
  className
}) => {
  return (
    <div className={classNames(!showLabel && 'sr-only', !isInline && bottomMargin_, className)}>
      <FluidText
        type="label"
        id={labelId}
        htmlFor={htmlFor}
        {...TEXT_CONFIGS[isInline ? TEXT_INLINE : TEXT_DEFAULT]}
      >
        {label}
        {showRequiredLabel && (
        <FluidText type="span" min="ws-text-md" max="ws-text-lg" className={requiredLabel_}>
          {' '}
          {requiredLabel}
        </FluidText>
        )}
      </FluidText>
      {tooltipDescription && (
        <>
          {'\u00A0'}
          <Tooltip ariaLabel={`${label}-tooltip`} description={tooltipDescription} removeUnderline alignTipToContentCenter forceVerticalAlignment="top" className={tooltip_}><Info /></Tooltip>
        </>
      )}
      {message && !isInline && (
        <FluidText
          type="p"
          all="ws-text-md"
          className={message_}
          {...(messageId && { id: messageId, role: 'status', 'aria-live': 'polite' })}
        >
          {message}
        </FluidText>
      )}
    </div>
  );
};

export const formFieldLabelShape = {
  htmlFor: PropTypes.string,
  labelId: PropTypes.string,
  messageId: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  requiredLabel: PropTypes.string,
  isInline: PropTypes.bool,
  message: PropTypes.string,
};

FormFieldLabel.propTypes = formFieldLabelShape;
