/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const LevelUp = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M18.5541 0.502548C18.5323 0.477951 18.509 0.454199 18.4843 0.431402C18.2919 0.253712 18.0485 0.165935 17.8057 0.166016C17.563 0.165935 17.3196 0.253712 17.1272 0.431402C17.1025 0.454199 17.0792 0.477951 17.0574 0.502548L1.11597 17.7629C0.741259 18.1687 0.766392 18.8013 1.17211 19.176C1.57783 19.5507 2.21049 19.5256 2.58521 19.1199L17.8058 2.64L33.0263 19.1199C33.401 19.5256 34.0337 19.5507 34.4394 19.176C34.8451 18.8013 34.8703 18.1687 34.4955 17.7629L18.5541 0.502548ZM34.4394 34.6873C34.0337 35.062 33.401 35.0368 33.0263 34.6311L17.8058 18.1512L2.58521 34.6311C2.21049 35.0368 1.57783 35.062 1.17211 34.6873C0.766392 34.3125 0.741259 33.6799 1.11597 33.2742L17.0574 16.0138C17.0792 15.9892 17.1025 15.9654 17.1272 15.9426C17.3196 15.7649 17.563 15.6772 17.8058 15.6772C18.0485 15.6772 18.2919 15.7649 18.4843 15.9426C18.509 15.9654 18.5323 15.9892 18.5542 16.0138L34.4955 33.2742C34.8703 33.6799 34.8451 34.3125 34.4394 34.6873Z" fill={color} /></svg>;
};
LevelUp.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default LevelUp;