/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Email = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M21.7071 0.292891C21.9788 0.564529 22.0708 0.967761 21.9439 1.33035L14.9439 21.3303C14.8084 21.7173 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4061L9.2424 12.7576L0.593867 8.91381C0.219186 8.74728 -0.0158775 8.36891 0.000836285 7.95923C0.0175501 7.54956 0.282654 7.19159 0.669655 7.05614L20.6697 0.056139C21.0322 -0.0707664 21.4355 0.0212527 21.7071 0.292891ZM11.1928 12.2215L13.8894 18.2889L18.6085 4.80568L11.1928 12.2215ZM17.1943 3.39147L3.71108 8.1106L9.77854 10.8073L17.1943 3.39147Z" fill={color} /></svg>;
};
Email.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Email;