'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { toObjectId } from '@stackbit/annotations';
import { ComponentContextProvider } from '../ComponentContext';
import { spacingBottom } from './Subsection.module.scss';

const Subsection = ({
  subsectionName,
  entryId,
  children,
  sectionIndex,
  subsectionIndex,
  isFirstChild,
  isLastChild,
  collapseBottomMargin = false,
  className,
  shouldUseDarkTheme = false,
  colorScheme,
  addBottomSpacing = false,
  ...restProps
}) => {
  const subsectionId = `${subsectionName.toLowerCase()}-${sectionIndex}-${subsectionIndex}`;
  const hasBottomSpacing = addBottomSpacing || (!isLastChild && !collapseBottomMargin);

  // wrap sibling elements in a single parent wrapper
  const wrapSiblings = _children => {
    if (children.length > 1) return <div>{children}</div>;
    return children;
  };

  return (
    <ComponentContextProvider
      value={{
        name: subsectionName,
        sectionIndex,
        subsectionIndex,
        id: subsectionId,
        isFirstChild,
        isLastChild,
        shouldUseDarkTheme,
        colorScheme,
        entryId,
      }}
    >
      {React.Children.map(wrapSiblings(children), child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            id: subsectionId,
            className: classNames(
              subsectionName,
              hasBottomSpacing && spacingBottom,
              className,
              child.props?.className
            ),
            ...toObjectId(entryId),
            ...restProps,
          });
        }
        return child;
      })}
    </ComponentContextProvider>
  );
};

Subsection.propTypes = {
  subsectionName: PropTypes.string.isRequired,
  addBottomSpacing: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sectionIndex: PropTypes.number,
  subsectionIndex: PropTypes.number,
  isFirstChild: PropTypes.bool,
  isLastChild: PropTypes.bool,
  shouldUseDarkTheme: PropTypes.bool,
  collapseBottomMargin: PropTypes.bool,
  className: PropTypes.string,
  colorScheme: PropTypes.shape({}),
};

// eslint-disable-next-line react/display-name
const asSubsection = name => SubsectionComponentMarkup => ({ children, ...props }) => {
  SubsectionComponentMarkup.displayName = 'SubsectionInner';

  return (
    <Subsection {...props} subsectionName={name}>
      <SubsectionComponentMarkup>{children}</SubsectionComponentMarkup>
    </Subsection>
  );
};

export { Subsection, asSubsection };
