/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Hide = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M13.4699 12.9612L14.7505 14.3118C13.5613 14.8182 12.2807 15.1559 11 15.1559C5.96881 15.1559 1.02911 10.0911 0.480249 9.50027C-0.160083 8.90938 -0.160083 7.98087 0.480249 7.30557C0.846154 7.05234 2.40124 5.44849 4.50519 4.01348L5.69438 5.19527C4.23077 6.12381 3.04158 7.22116 2.12682 8.06528C1.94387 8.2341 1.94387 8.48732 2.12682 8.65615C4.04782 10.5976 7.70686 13.4676 11 13.4676C11.8233 13.4676 12.5551 13.2988 13.4699 12.9612ZM12.0062 11.6106L7.61539 7.13674C7.43244 7.5588 7.34096 7.98086 7.34096 8.40292C7.34096 10.26 8.98753 11.7794 11 11.7794C11.3659 11.7794 11.7318 11.695 12.0062 11.6106ZM21.5198 9.50027C21.2453 9.83791 19.6902 11.3573 17.6778 12.7079L19.5073 14.565C19.8732 14.9026 19.7817 15.4091 19.4158 15.7468C19.2328 15.9156 18.9584 16 18.7755 16C18.501 16 18.3181 15.9156 18.1351 15.7468L3.95634 1.39673C3.59044 1.05909 3.68191 0.552593 4.04781 0.214944C4.41372 -0.122704 4.96258 -0.0382864 5.32849 0.299362L7.52391 2.49408C8.53014 1.98761 9.81081 1.64996 11 1.64996C16.0312 1.64996 20.9709 6.71469 21.5198 7.30557C22.1601 7.89646 22.1601 8.90938 21.5198 9.50027ZM19.8732 8.06528C17.9522 6.20821 14.2931 3.3382 11 3.3382C10.2682 3.3382 9.53638 3.50702 8.7131 3.76026L10.0852 5.11086C11.0915 4.85762 12.1892 4.85762 13.6528 6.37704C14.1102 6.7991 14.4761 7.38998 14.5676 7.98086C14.659 8.57174 14.5676 9.07821 14.3846 9.50027L16.3056 11.4418C17.7692 10.5976 19.0499 9.50027 19.8732 8.65615C20.0561 8.48732 20.0561 8.2341 19.8732 8.06528Z" fill={color} /></svg>;
};
Hide.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Hide;