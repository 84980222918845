/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const ArrowUp = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711C15.3166 9.09763 14.6834 9.09763 14.2929 8.70711L9 3.41421V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V3.41421L1.70711 8.70711C1.31658 9.09763 0.683417 9.09763 0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L7.29289 0.292893Z" fill={color} /></svg>;
};
ArrowUp.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default ArrowUp;