export const isValidHexColor = string => {
  return /^#?([a-f0-9]{6}|[a-f0-9]{3})$/i.test(string);
};

export const getLongHexCode = string => {
  if (isValidHexColor(string)) {
    if (string.length >= 6) {
      return string;
    }
    return string
      .split('')
      .reduce((acc, char) => {
        return [...acc, ...(char === '#' ? [char] : [char, char])];
      }, [])
      .join('');
  }
  throw new Error(
    `\`getLongHexCode\` did not receive a string containing a valid hex code, received: ${string}`
  );
};

// converts hex value to an rgb array of integers between 0 - 255
export const hexToRgb = hex => {
  try {
    const longHex = getLongHexCode(hex);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longHex);
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
  } catch (e) {
    return null;
  }
};

// prevents colors from going out of range
export const getSafeRgbColor = rgbColor => {
  if (!rgbColor) return [255, 255, 255];
  const r = rgbColor[0] > -1 ? rgbColor[0] : 255;
  const g = rgbColor[1] > -1 ? rgbColor[1] : 255;
  const b = rgbColor[2] > -1 ? rgbColor[2] : 255;
  return [r, g, b];
};

export const getLuminescenceFromRgb = rgb => {
  // https://www.w3.org/TR/WCAG20-TECHS/G18.html
  const srgb = rgb.map(int => int / 255);
  const [R, G, B] = srgb.map(s => (s <= 0.04045 ? s / 12.92 : ((s + 0.055) / 1.055) ** 2.4));
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

export const getDoesMeetContrastRequirements = (hexColor1, hexColor2) => {
  const rgb1 = hexToRgb(hexColor1);
  const rgb2 = hexToRgb(hexColor2);
  if (rgb1 === null || rgb2 === null) {
    throw new Error(
      `\`getDoesMeetContrastRequirements\` did not receive a string containing a valid hex code, received: (${hexColor1}, ${hexColor2})`
    );
  }
  // https://www.w3.org/TR/WCAG20-TECHS/G18.html
  const luminescence1 = getLuminescenceFromRgb(rgb1);
  const luminescence2 = getLuminescenceFromRgb(rgb2);
  const [L1, L2] =
    luminescence1 > luminescence2 ? [luminescence1, luminescence2] : [luminescence2, luminescence1];
  return (L1 + 0.05) / (L2 + 0.05) >= 4.5;
};

export const getIsDarkThemeFromBackgroundHexColor = hexColorString => {
  const textColor = '#32302f'; // $color-slate-20 aka $color-text
  try {
    const canUseDefaultTheme = getDoesMeetContrastRequirements(hexColorString, textColor);
    return !canUseDefaultTheme;
  } catch (e) {
    // Just use the default theme if we can't tell
    return false;
  }
};
