/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const Show = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M21.5198 5.44376C20.9709 4.87501 16.0312 0 11 0C5.96881 0 1.02911 4.87501 0.480249 5.44376C-0.160083 6.01251 -0.160083 6.98749 0.480249 7.55624C1.02911 8.12499 5.96881 13 11 13C16.0312 13 20.9709 8.12499 21.5198 7.55624C22.1601 6.98749 22.1601 6.01251 21.5198 5.44376ZM19.8732 6.74374C17.9522 8.61249 14.2931 11.375 11 11.375C7.70686 11.375 4.04782 8.61249 2.12682 6.74374C1.94387 6.58124 1.94387 6.3375 2.12682 6.175C4.04782 4.30625 7.70686 1.54375 11 1.54375C14.2931 1.54375 17.9522 4.30625 19.8732 6.175C20.0561 6.41876 20.0561 6.58124 19.8732 6.74374ZM14.659 6.5C14.659 8.2875 13.0125 9.75 11 9.75C8.98753 9.75 7.34096 8.2875 7.34096 6.5C7.34096 4.7125 8.98753 3.25 11 3.25C13.0125 3.25 14.659 4.7125 14.659 6.5Z" fill={color} /></svg>;
};
Show.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default Show;