'use client';

import React from 'react';
import { Helmet } from 'react-helmet-async';
import getFrameworkEnvVar from 'lib/get-framework-env-var';

const WRITE_KEY = getFrameworkEnvVar('SEGMENT_KEY');
const INTEGRATIONS = {
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
  ACCEPT_ALL: { All: true, 'Segment.io': true },
  ONLY_NECESSARY: { All: false, 'Segment.io': true },
};

export const loadSegmentAnalyticsJs = ({ consent }) =>
  window?.analytics?.load &&
  window.analytics.load(WRITE_KEY, {
    integrations: consent ? INTEGRATIONS.ACCEPT_ALL : INTEGRATIONS.ONLY_NECESSARY,
  });

export const SegmentIoScript = () => {
  if (!WRITE_KEY) return null;

  return (
    <Helmet>
      <script type="text/javascript" data-testid="SegmentIoScript">
        {`
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${WRITE_KEY}";analytics.SNIPPET_VERSION="4.15.2";
          analytics.ready(function() {window?.ga && window.ga(tracker => { window.clientId = tracker.get('clientId'); })})
        }}();
      `}
      </script>
    </Helmet>
  );
};
