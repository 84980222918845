'use client';

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { navigate } from 'gatsby';
import { DEFAULT_LOCALE_LOWERCASE } from 'src/utils/localization';

export const useHandleContentChange = () => {
  if (process.env.NODE_ENV === 'production') return undefined;

  const { locale } = useTranslationContext();

  useEffect(() => {
    const handleContentChange = async event => {
      event.preventDefault();
      await fetch('/__refresh', { method: 'POST' });
    };

    window.addEventListener('stackbitObjectsChanged', handleContentChange);

    return () => {
      window.removeEventListener('stackbitObjectsChanged', handleContentChange);
    };
  }, []);

  // sync the page locale with the locale switcher
  useEffect(() => {
    const handleStackbitLocaleChanged = event => {
      let {
        detail: { locale: newLocale },
      } = event;
      // newLocale will be an empty string (though previously was `null`) when locale switcher is in "global" mode
      // so we should navigate back to EN if not already there
      newLocale = newLocale?.toLowerCase() || DEFAULT_LOCALE_LOWERCASE;
      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(locale, newLocale);

      if (newPath !== currentPath) navigate(newPath);
    };

    window.addEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged);
    return () => {
      window.removeEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged);
    };
  }, [locale]);

  // sync locale switcher with the page locale
  // only works if the switcher is _not_ in "global" mode
  // useEffect(() => {
  //   // need to figure out how to avoid conflicting with the effect above this
  //   // window?.stackbit?.setLocale(locale.replace('ca', 'CA'));
  // }, [locale]);

  return undefined;
};
