'use client';

import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import adaEmbed from '@ada-support/embed2';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout';
import { isWindowDefined } from 'src/utils/window';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import './Chatbot.scss';

export const BOT_HANDLES = {
  legacy: 'wealthsimplebot',
  default: 'wealthsimplegrbot',
};

export const toggleChatbot = adaEmbed.toggle;

export const Chatbot = ({ handle = BOT_HANDLES.default }) => {
  const componentContext = useComponentContext();
  const { isEn } = useTranslationContext();
  const toggleCallback = isDrawerOpen => {
    const eventName = isDrawerOpen
      ? EVENT_NAMES.CHATBOT_DRAWER_OPENED
      : EVENT_NAMES.CHATBOT_DRAWER_CLOSED;
    trackAnalyticsEvent(eventName, {}, componentContext);
  };
  const isEligible = isEn;

  useLayoutEffect(() => {
    if (isWindowDefined()) {
      if (isEligible) {
        adaEmbed.start({
          handle,
          toggleCallback,
        });
      }

      return () => {
        // Ada gets mad if you try to call stop() before calling start()
        if (isEligible) {
          adaEmbed.stop();
        }
      };
    }

    return undefined;
  }, []);

  return <></>;
};

Chatbot.propTypes = {
  handle: PropTypes.string,
};
