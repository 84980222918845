import React from 'react';
import PropTypes from 'prop-types';

const PageContent = ({ children, defaultBackground, defaultSectionSpacing, colorScheme }) => {
  return (
    <>
      {React.Children.toArray(children)
        .filter(child => child && React.isValidElement(child)) // remove nullish children
        .map((child, index, filteredChildren) => {
          // Pass next section's background prop as the default value of wrapperBackground
          const { props: { background: nextBackground = defaultBackground } = {} } =
            filteredChildren[index + 1] ?? {};
          const {
            props: {
              wrapperBackground = nextBackground,
              zIndex: zIndexOverride,
              background = defaultBackground,
              verticalSpacing = defaultSectionSpacing,
            } = {},
          } = child;
          const zIndex = zIndexOverride ?? Math.max(filteredChildren.length - index, 1);

          return React.cloneElement(child, {
            sectionIndex: index,
            wrapperBackground,
            zIndex,
            background,
            verticalSpacing,
            colorScheme,
          });
        })}
    </>
  );
};

PageContent.propTypes = {
  /** The components that make up the content of the page. */
  children: PropTypes.node.isRequired,
  /** The default background colour of any `Section` instances in the children. See [Section](/?path=/docs/components-layout-section--docs). */
  defaultBackground: PropTypes.string,
  /** The default spacing of any `Section` instances in the children. See [Section](/?path=/docs/components-layout-section--docs). */
  defaultSectionSpacing: PropTypes.oneOf(['normal', 'tight']),
  /** Deprecated - do not use */
  colorScheme: PropTypes.shape({}),
};

export { PageContent };
