import { useCallback, useLayoutEffect, useState } from 'react';

export const useClientRect = () => {
  const [node, setNode] = useState(null);
  const [rect, setRect] = useState({});

  const ref = useCallback(_node => {
    if (_node) {
      setNode(_node);
    }
  }, []);

  useLayoutEffect(() => {
    const set = () =>
      window.requestAnimationFrame(() => setRect(node ? node.getBoundingClientRect() : {}));

    if (node) {
      set();
      window.addEventListener('resize', set);

      return () => {
        setNode(null);
        window.removeEventListener('resize', set);
      };
    }

    return undefined;
  }, [node]);

  return [ref, rect, node];
};
