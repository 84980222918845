/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const ExternalLink = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M13 2C12.4477 2 12 1.55228 12 1C12 0.447715 12.4477 0 13 0H19C19.5523 0 20 0.447715 20 1V7C20 7.55228 19.5523 8 19 8C18.4477 8 18 7.55228 18 7V3.41421L13.9142 7.5L16.7071 10.2929C16.8946 10.4804 17 10.7348 17 11V17C17 17.7956 16.6839 18.5587 16.1213 19.1213C15.5587 19.6839 14.7956 20 14 20H3C2.20437 20 1.44128 19.684 0.878664 19.1213C0.316077 18.5587 0 17.7956 0 17V6C0 5.20437 0.316057 4.4413 0.878674 3.87869C0.87868 3.87869 0.878686 3.87868 0.878693 3.87867M0.878693 3.87867C1.4413 3.31606 2.20437 3 3 3H9C9.26522 3 9.51957 3.10536 9.70711 3.29289L12.5 6.08579L16.5858 2H13M11.0858 7.5L8.58579 5H3C2.73478 5 2.48043 5.10536 2.29291 5.29289L2.29289 5.29291C2.10536 5.48043 2 5.73478 2 6V17C2 17.2652 2.10534 17.5195 2.29292 17.7071L1.58648 18.4135L2.29293 17.7071C2.48044 17.8946 2.73477 18 3 18H14C14.2652 18 14.5195 17.8947 14.7071 17.7071C14.8947 17.5195 15 17.2652 15 17V11.4142L12.5 8.91421L8.70711 12.7071C8.31658 13.0976 7.68342 13.0976 7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L11.0858 7.5Z" fill={color} /></svg>;
};
ExternalLink.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default ExternalLink;