/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const AssetManagers = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 56 34" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M17 16C20.866 16 24 12.866 24 9C24 5.13401 20.866 2 17 2C13.134 2 10 5.13401 10 9C10 12.866 13.134 16 17 16ZM26 9C26 12.2114 24.318 15.03 21.7868 16.6228C24.1833 17.2783 26.2987 18.4345 28.031 20.0118C29.8316 18.4896 31.9529 17.3351 34.2793 16.664C31.7114 15.0789 30 12.2393 30 9C30 4.02944 34.0294 0 39 0C43.9706 0 48 4.02944 48 9C48 12.2393 46.2886 15.0789 43.7207 16.664C50.8129 18.7099 56 25.2492 56 33C56 33.5523 55.5523 34 55 34C54.4477 34 54 33.5523 54 33C54 24.7157 47.2843 18 39 18C35.3557 18 32.015 19.2996 29.416 21.4606C28.9013 21.8885 28.4157 22.3502 27.9627 22.8423C25.5024 25.5142 24 29.0816 24 33C24 33.5523 23.5523 34 23 34C22.4477 34 22 33.5523 22 33C22 28.5134 23.7381 24.4327 26.5776 21.3945C24.1739 19.2567 20.8864 18 17 18C8.71573 18 2 24.7157 2 33C2 33.5523 1.55228 34 1 34C0.447715 34 0 33.5523 0 33C0 25.2492 5.18708 18.7099 12.2793 16.664C9.71135 15.0789 8 12.2393 8 9C8 4.02944 12.0294 0 17 0C21.9706 0 26 4.02944 26 9ZM39 16C42.866 16 46 12.866 46 9C46 5.13401 42.866 2 39 2C35.134 2 32 5.13401 32 9C32 12.866 35.134 16 39 16Z" fill={color} /></svg>;
};
AssetManagers.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default AssetManagers;